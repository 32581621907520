import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../components/Hooks/axios";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import { defaultPostData } from "../../redux/store";
import styles from "./Template.module.scss";

const Container3 = () => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);
	const [activePost, setActivePost] = useState(null);

	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data?.length === 0) {
					setPostData(defaultPostData);
					setActivePost(defaultPostData[0]);
				} else {
					setPostData(data);
					setActivePost(data[0]);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	const handleGallery = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		navigate("/Gallery");
	};

	return (
		<div className={styles.Container3}>
			<div className={styles.MYGalleryWrapper}>
				<h1>MyGallery</h1>
			</div>

			<div className={styles.CarouselWrapper}>
				<div className={styles.MainImageCarouselCont}>
					<div className={styles.MainImageWrapper}>
						{activePost?.mediaType === "image" ? (
							<img src={activePost ? activePost?.mediaUrl[0] : ""} alt="" />
						) : (
							<video src={activePost ? activePost?.mediaUrl[0] : ""} controls></video>
						)}
					</div>
				</div>

				<div className={styles.SubImageCarouselCont}>
					<div className={styles.InnerSubImageCarousel}>
						<AliceCarousel
							disableDotsControls
							disableButtonsControls
							autoPlay
							infinite
							autoPlayInterval={5000}
							responsive={{
								0: {
									items: 2,
								},
								400: {
									items: 3,
								},
								520: {
									items: 4,
								},
								700: {
									items: 5,
									itemsFit: "contain",
								},
							}}>
							{postData.slice(0, 5).map((data, index) => {
								return (
									<div
										className={styles.BoxWrapper}
										key={index}
										onDragStart={(e) => e.preventDefault()}
										onClick={() => setActivePost(data)}>
										{data?.mediaType === "image" ? (
											<img src={data?.mediaUrl[0]} alt="" />
										) : (
											<video src={data?.mediaUrl[0]}></video>
										)}
									</div>
								);
							})}
						</AliceCarousel>
					</div>
				</div>
			</div>

			<div className={styles.ViewAllWrapper}>
				<button onClick={handleGallery}>View all {">"}</button>
			</div>
		</div>
	);
};

export default Container3;
