import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template10.module.scss";
import TextArea from "./TextArea";
import Con2c from "./images/Con2.png";

const Container2 = ({
	editable,
	intro,
	setIntro,
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	setShowAboutMe,
	introImg1Position,
	setIntroImg1Position,
	introImg2Position,
	setIntroImg2Position,
}) => {
	return (
		<div className={styles.contain2}>
			<div className={styles.imageContainer2}>
				<div>
					{!editable && (
						<ImageUpload
							className={styles.introImg1Btn}
							setImage={setIntroImg1}
							setImageMobile={setIntroImg1Mobile}
							image={introImg1}
							imageMobile={introImg1Mobile}
							activeId={"introImg1Position"}
							initialPosition={introImg1Position}
							setInitialPosition={setIntroImg1Position}
						/>
					)}
					<img src={introImg1} className={styles.Con2Image1} alt="Intro Image 1" />
				</div>
				<div>
					{!editable && (
						<ImageUpload
							className={styles.introImg2Btn}
							setImage={setIntroImg2}
							setImageMobile={setIntroImg2Mobile}
							image={introImg2}
							imageMobile={introImg2Mobile}
							activeId={"introImg2Position"}
							initialPosition={introImg2Position}
							setInitialPosition={setIntroImg2Position}
						/>
					)}
					<img src={introImg2} className={styles.Con2Image2} alt="Intro Image 2" />
				</div>
			</div>
			<div>
				<img src={Con2c} className={styles.Con2Background} alt="" />
			</div>
			<div className={styles.Con2Text}>
				<h4 className={styles.h4}>My Intro</h4>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<TextArea className={styles.IntroContain2} readOnly={editable} value={intro} setValue={setIntro} />
			</div>
			<div className={styles.ButtonCont2}>
				<button onClick={() => setShowAboutMe(true)}>ABOUT ME</button>
				{/* <button className={styles.hireCon2}>HIRE ME</button> */}
			</div>
		</div>
	);
};
export default Container2;
