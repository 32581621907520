import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import style from "./Template6.module.scss";
import TextArea from "./TextArea";

function Container4({ editable, shortStory, setShortStory }) {
	return (
		<div className={style.container4}>
			{!editable && <div className={style.shortMessage}>Short Message</div>}
			{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
			<TextArea className={style.galleryDesc} readOnly={editable} value={shortStory} setValue={setShortStory} />
			<div className={style.thankYou}>Thank you ! Visit Again</div>
		</div>
	);
}

export default Container4;
