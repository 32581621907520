import React from "react";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template15.module.scss";
import background from "./assets/backGround.png";
import { useSelector } from "react-redux";

function Container3({
	editable,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	introImg3,
	introImg3Mobile,
	setIntroImg3,
	setIntroImg3Mobile,
	introImg4,
	introImg4Mobile,
	setIntroImg4,
	setIntroImg4Mobile,
	introImg5,
	introImg5Mobile,
	setIntroImg5,
	setIntroImg5Mobile,
	introImg2Position,
	setIntroImg2Position,
	introImg3Position,
	setIntroImg3Position,
	introImg4Position,
	setIntroImg4Position,
	introImg5Position,
	setIntroImg5Position,
}) {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.Container3}>
			<div className={styles.background}>
				<img src={background} alt="" />
			</div>
			<div className={styles.introImages}>
				<div className={styles.left}>
					<div className={styles.introImg2}>
						<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
							{width < height && width < 931 ? (
								<img src={introImg2Mobile} alt="introImg2Mobile" />
							) : (
								<img src={introImg2} alt="introImg2" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg2}
								setImageMobile={setIntroImg2Mobile}
								image={introImg2}
								imageMobile={introImg2Mobile}
								activeId={"introImg2Position"}
								initialPosition={introImg2Position}
								setInitialPosition={setIntroImg2Position}
							/>
						)}
					</div>
					<div className={styles.introImg3}>
						<ImageRender initialPosition={introImg3Position} editable={editable} currentId={"introImg3Position"}>
							{width < height && width < 931 ? (
								<img src={introImg3Mobile} alt="introImg3Mobile" />
							) : (
								<img src={introImg3} alt="introImg3" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg3}
								setImageMobile={setIntroImg3Mobile}
								image={introImg3}
								imageMobile={introImg3Mobile}
								activeId={"introImg3Position"}
								initialPosition={introImg3Position}
								setInitialPosition={setIntroImg3Position}
							/>
						)}
					</div>
				</div>
				<div className={styles.right}>
					<div className={styles.introImg4}>
						<ImageRender initialPosition={introImg4Position} editable={editable} currentId={"introImg4Position"}>
							{width < height && width < 931 ? (
								<img src={introImg4Mobile} alt="introImg4Mobile" />
							) : (
								<img src={introImg4} alt="introImg4" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg4}
								setImageMobile={setIntroImg4Mobile}
								image={introImg4}
								imageMobile={introImg4Mobile}
								activeId={"introImg4Position"}
								initialPosition={introImg4Position}
								setInitialPosition={setIntroImg4Position}
							/>
						)}
					</div>
					<div className={styles.introImg5}>
						<ImageRender initialPosition={introImg5Position} editable={editable} currentId={"introImg5Position"}>
							{width < height && width < 931 ? (
								<img src={introImg5Mobile} alt="introImg5Mobile" />
							) : (
								<img src={introImg5} alt="introImg5" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg5}
								setImageMobile={setIntroImg5Mobile}
								image={introImg5}
								imageMobile={introImg5Mobile}
								activeId={"introImg5Position"}
								initialPosition={introImg5Position}
								setInitialPosition={setIntroImg5Position}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Container3;
