import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../../components/TextArea";
import style from "./Template8.module.scss";

function Container2({
	editable,
	intro,
	setIntro,
	introImage,
	introImageMobile,
	setIntroImage,
	setIntroImageMobile,
	setShowAboutMe,
	introImgPosition,
	setIntroImgPosition,
}) {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={style.container2}>
			<div className={style.left}>
				<div className={style.Text}>
					{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}

					<TextArea className={style.desc} readOnly={editable} value={intro} setValue={setIntro} />
				</div>

				<div className={style.buttons}>
					<button onClick={() => setShowAboutMe(true)} id="aboutme">
						About me
					</button>
					{/* <button id="hireme">Hire me</button> */}
				</div>
			</div>

			<div className={style.right}>
				<div className={style.hero}>
					{!editable && (
						<ImageUpload
							className={style.IntroImage}
							setImage={setIntroImage}
							setImageMobile={setIntroImageMobile}
							image={introImage}
							imageMobile={introImageMobile}
							activeId={"introImgPosition"}
							initialPosition={introImgPosition}
							setInitialPosition={setIntroImgPosition}
						/>
					)}

					<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
						{width < 931 && height > 600 ? (
							<img src={introImageMobile} alt="introImageMobile" className={style.frame} />
						) : (
							<img src={introImage} alt="introImage" className={style.frame} />
						)}
					</ImageRender>

					<div className={style.textm}>M</div>

					<div className={style.triangle}></div>
				</div>
			</div>
		</div>
	);
}

export default Container2;
