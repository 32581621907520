import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import axios from "../../components/Hooks/axios";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import { defaultPostData } from "../../redux/store";
import styles from "./Template2.module.scss";
import "./Template2.scss";
import TextArea from "./TextArea";

const Container3 = ({ editable, shortMessage, setShortMessage }) => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);

	const [index, setIndex] = useState(0);
	const [mouseOver, setMouseOver] = useState(false);
	const post1Ref = useRef();
	const post2Ref = useRef();
	const post3Ref = useRef();
	const post4Ref = useRef();
	const post5Ref = useRef();

	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data.length === 0) setPostData(defaultPostData);
				else setPostData(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	useEffect(() => {
		if (index === 0 && postData[0]) post1Ref?.current?.click();
		if (index === 1 && postData[1]) post2Ref?.current?.click();
		if (index === 2 && postData[2]) post3Ref?.current?.click();
		if (index === 3 && postData[3]) post4Ref?.current?.click();
		if (index === 4 && postData[4]) post5Ref?.current?.click();

		const currentIndex = setInterval(() => {
			if (index < 5) if (!mouseOver) setIndex(index + 1);
		}, 5000);

		setTimeout(() => {
			if (index === postData?.length - 1 || index === 4) setIndex(0);
		}, 5000);

		return () => clearInterval(currentIndex);
	}, [index, mouseOver]);

	const handleGallery = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		navigate("/Gallery");
	};
	return (
		<div className={styles.Container3}>
			<div className={styles.Top}>
				<svg xmlns="http://www.w3.org/2000/svg" width="152" height="37" viewBox="0 0 152 37" fill="none">
					<path
						d="M1.99268 22.4234C12.5124 32.0844 40.6364 45.6098 68.9752 22.4234C104.399 -6.5596 137.89 -2.05114 150.127 19.2031"
						stroke="#DDAD51"
						strokeWidth="2.88545"
						strokeLinecap="round"
					/>
				</svg>
				<h1>My Gallery</h1>
				<svg xmlns="http://www.w3.org/2000/svg" width="152" height="38" viewBox="0 0 152 38" fill="none">
					<path
						d="M1.729 22.8111C12.2487 32.4721 40.3728 45.9975 68.7116 22.8111C104.135 -6.17191 137.627 -1.66344 149.864 19.5908"
						stroke="#DDAD51"
						strokeWidth="2.88545"
						strokeLinecap="round"
					/>
				</svg>
				<button onClick={handleGallery}>
					{" "}
					<span>V</span>IEW <span>A</span>LL
				</button>
			</div>

			<div className={styles.Middle}>
				<div className={styles.CarouseWrapper}>
					{postData[0] && <input type="radio" name="btn" id="Tem2post1" onClick={() => setIndex(0)} ref={post1Ref} />}
					{postData[1] && <input type="radio" name="btn" id="Tem2post2" onClick={() => setIndex(1)} ref={post2Ref} />}
					{postData[2] && <input type="radio" name="btn" id="Tem2post3" onClick={() => setIndex(2)} ref={post3Ref} />}
					{postData[3] && <input type="radio" name="btn" id="Tem2post4" onClick={() => setIndex(3)} ref={post4Ref} />}
					{postData[4] && <input type="radio" name="btn" id="Tem2post5" onClick={() => setIndex(4)} ref={post5Ref} />}

					{postData?.slice(0, 5).map((data, i) => {
						return (
							<div
								className={styles.ImageWrapper}
								key={i}
								id={i === 0 ? "ActiveImageContainer" : ""}
								onMouseEnter={() => {
									if (index === i) setMouseOver(true);
								}}
								onMouseLeave={() => setMouseOver(false)}>
								<div className={index === i ? styles.ImgWrapperActive : styles.ImgWrapperNormal}>
									{data.mediaType === "video" ? (
										<video src={data.mediaUrl[0]} controls></video>
									) : data.mediaType === "image" ? (
										<img src={data.mediaUrl[0]} alt="" />
									) : (
										""
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className={styles.Bottom}>
				{!editable && <h3>SHORT MESSAGE</h3>}

				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}

				<TextArea className={styles.p} readOnly={editable} value={shortMessage} setValue={setShortMessage} />

				<h2>Thank you ! Visit Again !</h2>
			</div>
		</div>
	);
};

export default Container3;
