import styles from "../Template2.module.scss";
const Posts = ({ data }) => {
    console.log(data)
	return (
		<div className={styles.postMainContainer}>
			<div className={styles.media}>
				<img src={data.mediaUrl[0]} alt="" />
			</div>
			<div className={styles.profile}>
				<img src={data.userId.ProfilePic} alt="" />
				<div className={styles.profileDetails}>
					{/* <h4>{data.userId.displayName}</h4> */}
					<h4>{data.userId.username}</h4>
					<h5>{data.userId.GetstatedName}</h5>
				</div>
			</div>
			<div className={styles.details} id="details">
				<p>{data.postDesc}</p>
			</div>
		</div>
	);
};

export default Posts;
