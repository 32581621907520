import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template10.module.scss";
import TextArea from "./TextArea";
import Con2d from "./images/Con2d.png";

const Container2 = ({
	editable,
	intro,
	setIntro,
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	setShowAboutMe,
	introImg1Position,
	setIntroImg1Position,
	introImg2Position,
	setIntroImg2Position,
}) => {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.contain2}>
			<div className={styles.imageContainer2}>
				<div className={styles.TopImgSection}>
					{!editable && (
						<ImageUpload
							className={styles.introImg1mBtn}
							setImage={setIntroImg1}
							setImageMobile={setIntroImg1Mobile}
							image={introImg1}
							imageMobile={introImg1Mobile}
							activeId={"introImg1Position"}
							initialPosition={introImg1Position}
							setInitialPosition={setIntroImg1Position}
						/>
					)}
					<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
						{width < 950 && height > 400 ? (
							<img src={introImg1Mobile} className={styles.Con2Image1} alt="introImg1Mobile" />
						) : (
							<img src={introImg1} className={styles.Con2Image1} alt="introImg1" />
						)}
					</ImageRender>
				</div>

				<div className={styles.BottomImgSection}>
					{!editable && (
						<ImageUpload
							className={styles.introImg2mBtn}
							setImage={setIntroImg2}
							setImageMobile={setIntroImg2Mobile}
							image={introImg2}
							imageMobile={introImg2Mobile}
							activeId={"introImg2Position"}
							initialPosition={introImg2Position}
							setInitialPosition={setIntroImg2Position}
						/>
					)}
					<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
						{width < 950 && height > 400 ? (
							<img src={introImg2Mobile} className={styles.Con2Image2} alt="introImg2Mobile" />
						) : (
							<img src={introImg2} className={styles.Con2Image2} alt="introImg2" />
						)}
					</ImageRender>
				</div>
			</div>

			<div>
				<img src={Con2d} className={styles.Con2Background} alt="" />
			</div>

			<div className="Con2Text">
				<h4 className={styles.h4}>My Intro</h4>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIconM} />}
				<TextArea className={styles.IntroContain2} readOnly={editable} value={intro} setValue={setIntro} />
			</div>

			<div className={styles.ButtonCont2}>
				<button onClick={() => setShowAboutMe(true)}>ABOUT ME</button>
				{/* <button className={styles.hireCon2}>HIRE ME</button> */}
			</div>
		</div>
	);
};
export default Container2;
