import React from "react";
import style from "./Template8.module.scss";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";

function Container1({
	editable,
	name,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	setName,
	followers,
	setShowRating,
	ratingData,
	coverImgPosition,
	setCoverImgPosition,
}) {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={style.container1}>
			<div className={style.background}>
				<img src={coverImage} alt="" />
			</div>

			<div className={style.cover}>
				<div className={style.left}>
					<div className={style.name}>
						<div className={style.sur}>I'm</div>
						<div
							className={style.full}
							style={{
								fontSize: name.length > 10 ? (name.length >= 17 ? "3.6rem" : "4.5rem") : "6.4rem",
							}}>
							{name}
						</div>
					</div>
				</div>

				<div className={style.right}>
					<div className={style.hero}>
						{!editable && (
							<ImageUpload
								className={style.CoverImage}
								setImage={setCoverImage}
								setImageMobile={setCoverImageMobile}
								image={coverImage}
								imageMobile={coverImageMobile}
								activeId={"coverImg"}
								initialPosition={coverImgPosition}
								setInitialPosition={setCoverImgPosition}
							/>
						)}

						<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImg"}>
							{width < 950 && height > 600 ? (
								<img src={coverImageMobile} alt="coverImageMobile" />
							) : (
								<img src={coverImage} alt="cover" />
							)}
						</ImageRender>

						<div className={style.frame}>
							<div className={style.inner}>
								<div className={style.buttons}>
									{userId === currentUserId ? (
										<button
											className={style.follow}
											onClick={() => {
												window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
											}}>
											Following List
										</button>
									) : (
										<FollowButton className={style.follow} />
									)}
									<MessageButton className={style.message} />
								</div>

								{userId === currentUserId ? (
									<div
										className={style.followers}
										style={{ cursor: "pointer" }}
										onClick={() => {
											window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
										}}>
										<div className={style.count}>{followers}</div>
										<div className={style.text}>Followers</div>
									</div>
								) : (
									<div className={style.followers}>
										<div className={style.count}>{followers}</div>
										<div className={style.text}>Followers</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className={style.star} onClick={handleRating}>
					{ratingData}
				</div>
			</div>
		</div>
	);
}

export default Container1;
