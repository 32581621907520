import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import jems from "../../assets/business/image/jems.png";
import jennyWill from "../../assets/business/image/jennyWill.png";
import profileImage from "../../assets/business/image/profileImage.svg";
import "./Btemplate7.scss";
import styles from "./Btemplate7.module.scss";
import MobileCorousel from "./components/MobileCarousel";
import Posts from "./components/Posts";
import gallery1 from "./assets/gallery1.png";
import gallery2 from "./assets/gallery2.png";
import gallery3 from "./assets/gallery3.png";

const posts = [
	{
		id: 0,
		mediaUrl: gallery1,
		postDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
		name: "Jenny Wilson",
		userName: "@JENNYWILL",
		profilePic: profileImage,
	},
	{
		id: 1,
		mediaUrl: gallery2,
		postDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
		name: "Jenny Wilson",
		userName: "@JENNYWILL",
		profilePic: profileImage,
	},
	{
		id: 2,
		mediaUrl: gallery3,
		postDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
		name: "Jenny Wilson",
		userName: "@JENNYWILL",
		profilePic: profileImage,
	},
	{
		id: 3,
		mediaUrl: gallery2,
		postDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
		name: "Jenny Wilson",
		userName: "@JENNYWILL",
		profilePic: profileImage,
	},
];

const items = posts.map((post) => <Posts key={post.id} data={post} />);
const responsive = {
	0: { items: 3 },
};
function Container5() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		function handelResize() {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width, height]);

	const paddingRightVW = 30;
	const paddingRightPixel = (width * paddingRightVW) / 100;
	return (
		<section className={styles.Container5}>
			<div className={styles.topSection}>
				<h2>Our Gallery</h2>
				<p>View All</p>
			</div>
			<div className={styles.carousel} id="carouselB7">
				{width < height ? (
					<MobileCorousel data={posts} />
				) : (
					<AliceCarousel
						items={items}
						responsive={responsive}
						autoPlay
						autoPlayInterval={1000}
						animationDuration={1000}
						infinite
					/>
				)}
			</div>
			<div className={styles.thankYou}>
				<p>Thank you ! Visit Again</p>
			</div>
		</section>
	);
}

export default Container5;
