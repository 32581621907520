import React from "react";
import styles from "./Lodging.module.scss";

const Lodging = () => {
	return (
		<div className={styles.LodgingWrapper}>
			<div className={styles.Loading}>Loading...</div>
		</div>
	);
};

export default Lodging;
