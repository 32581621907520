import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import commentIcon from "../../assets/images/Comment.png";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import shareIcon from "../../assets/images/Share.png";
import axios from "../../components/Hooks/axios";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import LikeBtn from "../../components/LikeButton/LikeBtn";
import TextArea from "../../components/TextArea";
import { defaultPostData } from "../../redux/store";
import styles from "./Template5.module.scss";

//slide container
const ActiveCard = ({ data }) => {
	return (
		<>
			<div className={styles.activeContainer}>
				<div className={styles.imageSection}>
					<img src={data.mediaUrl[0]} alt="" />
				</div>
				<div className={styles.below}>
					<div className={styles.desc}>{data.description}</div>
					<LikeBtn data={data} Icon={styles.like} LikeIcon={styles.LikeIcon} />
					<div className={styles.icons}>
						<div className={styles.comment}>
							<div className={styles.commentIcon}>
								<img src={commentIcon} alt="" />
							</div>
							<div className={styles.commentCount}>{33}</div>
						</div>
						<div className={styles.shareIcon}>
							<img src={shareIcon} alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const responsive = {
	0: { items: 1 },
	651: { items: 1 },
	1024: { items: 1 },
};

function generateItems(posts) {
	const items = [];

	for (let i = 0; i < posts.length; i++) {
		items.push(
			<div className="item" data-value={i + 1} key={i}>
				<ActiveCard data={posts[i]} />
			</div>
		);
	}
	return items;
}

function Container4({ editable, shortMessage, setShortMessage }) {
	const [postData, setPostData] = useState([]);
	const { userId } = useSelector((state) => state.auth);
	const [items, setItems] = useState([]);
	//fecth posts
	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data.length === 0) {
					setPostData(defaultPostData);
					const generatedItems = generateItems(defaultPostData);
					setItems(generatedItems);
				} else {
					setPostData(data);
					const generatedItems = generateItems(data);
					setItems(generatedItems); //for corousel
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	//fetch pst end
	//for carousel
	//for carousel
	// const [active,setActive]=useState(posts[0]);
	const [activeIndex, setActiveIndex] = useState(0);
	const percent = 0.21071;
	const section = useRef(null);
	const [padding, setPadding] = useState(0);
	const handleSlideChanged = ({ item }) => {
		setActiveIndex(item);
	};

	const syncState = () => {
		const { current } = section;
		if (current) {
			setPadding(current.offsetWidth * percent);
		}
	};

	useEffect(syncState, []);
	const isMobile = window.innerWidth < 931 && window.innerHeight > 599;
	//carousel end//
	const navigate = useNavigate();
	const handleGallery = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		navigate("/Gallery");
	};
	return (
		<div className={styles.container4}>
			<div className={styles.headerSection}>
				<div className={styles.myGallery}>My Gallery</div>
				<div className={styles.viewAll} style={{ cursor: "pointer" }} onClick={handleGallery}>
					View All
				</div>
			</div>
			<div className={styles.carouselSection}>
				<div ref={section}>
					<AliceCarousel
						infinite
						mouseTracking
						disableButtonsControls
						responsive={responsive}
						items={items}
						{...(isMobile
							? {}
							: {
									paddingRight: padding,
									paddingLeft: padding,
							  })}
						onResized={syncState}
						onSlideChange={handleSlideChanged}
						autoPlay={true}
						autoPlayInterval={3000}
					/>
				</div>
			</div>
			<div className={styles.footer}>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<TextArea className={styles.thankDesc} readOnly={editable} value={shortMessage} setValue={setShortMessage} />
				<div className={styles.thanku}>Thank you ! Visit Again</div>
			</div>
		</div>
	);
}

export default Container4;
