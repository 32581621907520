import commentIcon from "../../../assets/images/Comment.png";
import shareIcon from "../../../assets/images/Share.png";
import LikeBtn from "../../../components/LikeButton/LikeBtn";
import style from "../Template8.module.scss";

const ActiveCard = ({ data }) => {
	return (
		<>
			<div className={style.activeContainer}>
				<div className={style.imageSection}>
					{data?.mediaType === "image" ? <img src={data?.mediaUrl} alt="" /> : <video src={data?.mediaUrl}></video>}
				</div>
				<div className={style.details}>
					<div className={style.upper}>
						<div className={style.profileLogo}>
							<img src={data?.userId?.ProfilePic} alt="" />
						</div>
						<div className={style.nameUsername}>
							<div className={style.name}>{data?.userId?.username}</div>
							<div className={style.userName}>{data?.userId?.displayName}</div>
						</div>
					</div>
					<div className={style.below}>
						<div className={style.desc}>{data?.caption}</div>
						<div className={style.icons}>
							<div className={style.like}>
								<LikeBtn data={data} Icon={style.likeIcon} LikeIcon={style.LikeIcon} Text={style.likeCount} />
							</div>
							<div className={style.comment}>
								<div className={style.commentIcon}>
									<img src={commentIcon} alt="" />
								</div>
								<div className={style.commentCount}>{data?.Comments?.length}</div>
							</div>
							<div className={style.shareIcon}>
								<img src={shareIcon} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ActiveCard;
