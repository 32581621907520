import React from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template3.module.scss";
import dot from "./images/dots.png";
import line from "./images/str.png";

const Container2 = ({
	editable,
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	introImg3,
	introImg3Mobile,
	setIntroImg3,
	setIntroImg3Mobile,
	introImg1Position,
	introImg2Position,
	introImg3Position,
	setIntroImg1Position,
	setIntroImg2Position,
	setIntroImg3Position,
}) => {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<>
			<div className={style.container2}>
				<div className={style.leftDot}>
					<img src={dot} alt="" />
				</div>

				<div className={style.rightDot}>
					<img src={dot} alt="" />
				</div>

				<div className={style.image_container}>
					<div className={style.frame1}>
						<img src={line} alt="" />
					</div>

					<div className={style.frame2}>
						<img src={line} alt="" />
					</div>

					<div className={style.frame3}>
						<img src={line} alt="" />
					</div>

					<div className={style.frame4}>
						<img src={line} alt="" />
					</div>

					<div className={style.frame5}>
						<img src={line} alt="" />
					</div>

					<div className={style.introImage1}>
						<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
							{width < 950 && height > 600 ? (
								<img src={introImg1Mobile} alt={"introImg1Mobile"} />
							) : (
								<img src={introImg1} alt={"introImg1"} />
							)}
						</ImageRender>
					</div>
					{!editable && (
						<ImageUpload
							className={style.editIntro1Icon}
							setImage={setIntroImg1}
							setImageMobile={setIntroImg1Mobile}
							image={introImg1}
							imageMobile={introImg1Mobile}
							activeId={"introImg1Position"}
							initialPosition={introImg1Position}
							setInitialPosition={setIntroImg1Position}
						/>
					)}

					<div className={style.introImage2}>
						<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
							{width < 950 && height > 600 ? (
								<img src={introImg2Mobile} alt={"introImg2Mobile"} loading="lazy" />
							) : (
								<img src={introImg2} alt={"introImg2"} loading="lazy" />
							)}
						</ImageRender>
					</div>
					{!editable && (
						<ImageUpload
							className={style.editIntro2Icon}
							setImage={setIntroImg2}
							setImageMobile={setIntroImg2Mobile}
							image={introImg2}
							imageMobile={introImg2Mobile}
							activeId={"introImg2Position"}
							initialPosition={introImg2Position}
							setInitialPosition={setIntroImg2Position}
						/>
					)}

					<div className={style.introImage3}>
						<ImageRender initialPosition={introImg3Position} editable={editable} currentId={"introImg3Position"}>
							{width < 950 && height > 600 ? (
								<img src={introImg3Mobile} alt={"introImg3Mobile"} loading="lazy" />
							) : (
								<img src={introImg3} alt={"introImg3"} loading="lazy" />
							)}
						</ImageRender>
					</div>
					{!editable && (
						<ImageUpload
							className={style.editIntro3Icon}
							setImage={setIntroImg3}
							setImageMobile={setIntroImg3Mobile}
							image={introImg3}
							imageMobile={introImg3Mobile}
							activeId={"introImg3Position"}
							initialPosition={introImg3Position}
							setInitialPosition={setIntroImg3Position}
						/>
					)}
				</div>

				<div className={style.myIntro}>My Intro</div>
			</div>
		</>
	);
};

export default Container2;
