import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AboutMe from "../../components/AboutMe/AboutMe";
import HomeButton from "../../components/HomeButton/HomeButton";
import Lodging from "../../components/Lodging/Lodging";
import NavBar from "../../components/NavBar/NavBar";
import Rating from "../../components/Rating/Rating";
import Container1 from "./Container1";
import Container2 from "./Container2";
import Container3 from "./Container3";
import Container4 from "./Container4";
import styles from "./Template1.module.scss";
import "./Template1.scss";
import setting from "./images/Setting.svg";

const Template1 = () => {
	document.documentElement.style.setProperty("--base-font-size", "100%");
	const { user, templateData, isLoading, currentUserId, userId } = useSelector((state) => state.auth);
	const [width, setWidth] = useState(window.innerWidth);
	const { state } = useLocation();

	useEffect(() => {
		function handelResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width]);

	if (width < 1728) document.documentElement.style.setProperty("--base-font-size", "90%");
	if (width < 1500) document.documentElement.style.setProperty("--base-font-size", "80%");
	if (width < 1350) document.documentElement.style.setProperty("--base-font-size", "70%");
	if (width < 1250) document.documentElement.style.setProperty("--base-font-size", "70%");
	if (width < 1150) document.documentElement.style.setProperty("--base-font-size", "65%");
	if (width < 1024) document.documentElement.style.setProperty("--base-font-size", "60%");
	if (width < 900) document.documentElement.style.setProperty("--base-font-size", "55%");
	if (width < 800) document.documentElement.style.setProperty("--base-font-size", "50%");

	const [editable, setEditable] = useState(true);
	const [showNav, setShowNav] = useState(false);
	const [showRating, setShowRating] = useState(false);
	const [ratingData, setRatingData] = useState("");
	const [showAboutMe, setShowAboutMe] = useState(false);
	const [name, setName] = useState("");
	const [reel1, setReel1] = useState("");
	const [reel1Mobile, setReel1Mobile] = useState("");
	const [reel2, setReel2] = useState("");
	const [reel2Mobile, setReel2Mobile] = useState("");
	const [reel3, setReel3] = useState("");
	const [reel3Mobile, setReel3Mobile] = useState("");
	const [coverImage, setCoverImage] = useState("");
	const [coverImageMobile, setCoverImageMobile] = useState("");
	const [postCount, setPostCount] = useState("");
	const [followers, setFollowers] = useState("");
	const [followings, setFollowings] = useState("");
	const [introText, setIntroText] = useState("");
	const [introImage, setIntroImage] = useState("");
	const [introImageMobile, setIntroImageMobile] = useState("");
	const [shortMessage, setShortMessage] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [liveIn, setLiveIn] = useState("");
	const [schooling, setSchooling] = useState("");
	const [profession, setProfession] = useState("");
	const [coverImgPosition, setCoverImgPosition] = useState("");
	const [introImgPosition, setIntroImgPosition] = useState("");
	const [reelImg1Position, setReelImg1Position] = useState("");
	const [reelImg2Position, setReelImg2Position] = useState("");
	const [reelImg3Position, setReelImg3Position] = useState("");

	useEffect(() => {
		if (state.viewMode) setEditable(false);
		if (templateData === null) return;
		// console.log(templateData);
		setRatingData(user?.Rating === undefined ? "0" : user?.Rating);
		setName(user?.username === "" || user?.username === undefined ? templateData?.name : user?.username);
		setReel1(templateData?.reel1);
		setReel1Mobile(templateData?.reel1Mobile);
		setReel2(templateData?.reel2);
		setReel2Mobile(templateData?.reel2Mobile);
		setReel3(templateData?.reel3);
		setReel3Mobile(templateData?.reel3Mobile);
		setCoverImage(templateData?.coverImage);
		setCoverImageMobile(templateData?.coverImageMobile);
		setPostCount(user?.postCount || "0");
		setFollowers(user?.followersData?.length || "0");
		setFollowings(user?.followingData?.length || "0");
		// setIntroText(templateData?.introText);
		setIntroText(user?.bio === "" ? templateData?.introText : user?.bio);
		setIntroImage(templateData?.introImage);
		setIntroImageMobile(templateData?.introImageMobile);
		setShortMessage(templateData?.shortMessage);
		setEmail(user?.email === "" || user?.email === undefined ? "James.test@mail.co" : user?.email);
		setPhone(user?.mobile_number === "" || user?.mobile_number === undefined ? "+91 323 323 3245" : user?.mobile_number);
		setLiveIn(templateData?.aboutMe?.liveIn);
		setSchooling(templateData?.aboutMe?.schooling);
		setProfession(templateData?.aboutMe?.profession);
		setCoverImgPosition(templateData?.coverImgPosition);
		setIntroImgPosition(templateData?.introImgPosition);
		setReelImg1Position(templateData?.reelImg1Position);
		setReelImg2Position(templateData?.reelImg2Position);
		setReelImg3Position(templateData?.reelImg3Position);
	}, [user, templateData]);

	const templateUpdatedData = {
		name,
		coverImage,
		coverImageMobile,
		reel1,
		reel2,
		reel3,
		reel1Mobile,
		reel2Mobile,
		reel3Mobile,
		introText,
		introImage,
		introImageMobile,
		shortMessage,
		coverImgPosition,
		introImgPosition,
		reelImg1Position,
		reelImg2Position,
		reelImg3Position,
		aboutMe: {
			email,
			phone,
			liveIn,
			schooling,
			profession,
		},
		hireMe: {
			hireable: false,
		},
	};

	return (
		<>
			{isLoading && <Lodging />}
			<NavBar
				editable={editable}
				setEditable={setEditable}
				showNav={showNav}
				setShowNav={setShowNav}
				templateUpdatedData={templateUpdatedData}
			/>
			{showRating && <Rating setShowRating={setShowRating} setRatingData={setRatingData} />}
			{showAboutMe && (
				<AboutMe
					editable={editable}
					setShowAboutMe={setShowAboutMe}
					email={email}
					setEmail={setEmail}
					phone={phone}
					setPhone={setPhone}
					liveIn={liveIn}
					setLiveIn={setLiveIn}
					schooling={schooling}
					setSchooling={setSchooling}
					profession={profession}
					WSS
					setProfession={setProfession}
				/>
			)}

			<div className={styles.TemplateWrapper} id="Temp1" style={{ background: "" }}>
				{!showNav && currentUserId === userId && user && !state.viewMode && (
					<img src={setting} alt="" className={styles.Setting} onClick={() => setShowNav(true)} />
				)}

				<div className={styles.Template1}>
					<HomeButton {...{ templateUpdatedData }} />

					<Container1
						editable={editable}
						name={name}
						reel1={reel1}
						reel1Mobile={reel1Mobile}
						setReel1={setReel1}
						setReel1Mobile={setReel1Mobile}
						reel2={reel2}
						setReel2={setReel2}
						reel2Mobile={reel2Mobile}
						setReel2Mobile={setReel2Mobile}
						reel3={reel3}
						setReel3={setReel3}
						reel3Mobile={reel3Mobile}
						setReel3Mobile={setReel3Mobile}
						coverImage={coverImage}
						coverImageMobile={coverImageMobile}
						setCoverImage={setCoverImage}
						setCoverImageMobile={setCoverImageMobile}
						setName={setName}
						postCount={postCount}
						followers={followers}
						followings={followings}
						setShowRating={setShowRating}
						ratingData={ratingData}
						coverImgPosition={coverImgPosition}
						setCoverImgPosition={setCoverImgPosition}
						reelImg1Position={reelImg1Position}
						reelImg2Position={reelImg2Position}
						reelImg3Position={reelImg3Position}
						setReelImg1Position={setReelImg1Position}
						setReelImg2Position={setReelImg2Position}
						setReelImg3Position={setReelImg3Position}
					/>

					<Container2
						editable={editable}
						intro={introText}
						setIntro={setIntroText}
						introImage={introImage}
						introImageMobile={introImageMobile}
						setIntroImage={setIntroImage}
						setIntroImageMobile={setIntroImageMobile}
						setShowAboutMe={setShowAboutMe}
						introImgPosition={introImgPosition}
						setIntroImgPosition={setIntroImgPosition}
					/>

					<Container3 />

					<Container4 editable={editable} shortStory={shortMessage} setShortStory={setShortMessage} />
				</div>
			</div>
		</>
	);
};

export default Template1;
