import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template1.module.scss";
import TextArea from "./TextArea";
import IntroAsset from "./images/IntroAsset.png";
import { useSelector } from "react-redux";

const Container2 = ({
	editable,
	intro,
	setIntro,
	introImage,
	introImageMobile,
	setIntroImage,
	setIntroImageMobile,
	setShowAboutMe,
	introImgPosition,
	setIntroImgPosition,
}) => {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.Container2}>
			<h4 className={styles.H4}>My Intro</h4>
			<div className={styles.Wrapper}>
				<div className={styles.Left}>
					<div className={styles.LeftInner}>
						<h4>My Intro</h4>
						{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}

						<TextArea className={styles.Intro} readOnly={editable} value={intro} setValue={setIntro} />

						<div className={styles.ButtonContainer}>
							<button onClick={() => setShowAboutMe(true)}>About Me</button>
							{/* <button>Hire Me</button> */}
						</div>
					</div>
				</div>

				<div className={styles.Right}>
					<div className={styles.RightInner}>
						{!editable && (
							<ImageUpload
								className={styles.IntroImage}
								setImage={setIntroImage}
								setImageMobile={setIntroImageMobile}
								image={introImage}
								imageMobile={introImageMobile}
								activeId={"introImgPosition"}
								initialPosition={introImgPosition}
								setInitialPosition={setIntroImgPosition}
							/>
						)}

						<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
							{width < 900 && height > 400 ? (
								<img src={introImageMobile} className={styles.Overlay} alt="" />
							) : (
								<img src={introImage} className={styles.Overlay} alt="" />
							)}
						</ImageRender>
						<img src={IntroAsset} className={styles.OverlayAsset} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Container2;
