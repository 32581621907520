import React from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import mobileStructure from "./images/molile.png";
import deskStructure from "./images/structure1.png";
import style from "./Template6.module.scss";

function Container1({
	editable,
	name,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	setName,
	followers,
	followings,
	setShowRating,
	ratingData,
	coverImgPosition,
	setCoverImgPosition,
}) {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={style.container1}>
			{!editable && (
				<ImageUpload
					className={style.Reel1UploadBtn}
					setImage={setCoverImage}
					setImageMobile={setCoverImageMobile}
					image={coverImage}
					imageMobile={coverImageMobile}
					activeId={"coverImgPosition"}
					initialPosition={coverImgPosition}
					setInitialPosition={setCoverImgPosition}
				/>
			)}
			<div className={style.coverImage}>
				<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
					{width < 950 && height > 600 ? (
						<img src={coverImageMobile} alt="coverImageMobile" />
					) : (
						<img src={coverImage} alt="coverImage" />
					)}
				</ImageRender>
				<div className={style.structure}>
					{width < 930 && height > 600 ? <img src={mobileStructure} alt="" /> : <img src={deskStructure} alt="" />}
				</div>
			</div>

			<div className={style.nameContainer}>
				<span
					style={{
						fontSize: name.length >= 10 ? (name.length >= 17 ? "7.58vh" : "10vh") : "14vh",
					}}>
					{name}
				</span>
				<div className={style.shadow}>{name}</div>
			</div>

			<div className={style.coverFooter}>
				<div className={style.coverLeft}>
					<div className={style.nameusername}>
						<div className={style.name}>{name}</div>
						<div className={style.username}>@{name}</div>
					</div>
					<div className={style.coverbuttons}>
						{userId === currentUserId ? (
							<button
								onClick={() => {
									window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
								}}>
								<p>{followings}</p>
								Following
							</button>
						) : (
							<FollowButton />
						)}
						<MessageButton />
					</div>
				</div>
				<div className={style.coverRight}>
					<div className={style.star} onClick={handleRating}>
						{ratingData}
					</div>

					{userId === currentUserId ? (
						<div
							className={style.followers}
							style={{ cursor: "pointer" }}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<div className={style.followerCount}>{followers}</div>
							<div className={style.textFollower}>Followers</div>
						</div>
					) : (
						<div className={style.followers}>
							<div className={style.followerCount}>{followers}</div>
							<div className={style.textFollower}>Followers</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Container1;
