import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template2.module.scss";
import TextArea from "./TextArea";
import { useSelector } from "react-redux";

const Container2 = ({
	editable,
	intro,
	setIntro,
	introImageMobile,
	introImage,
	setIntroImage,
	setIntroImageMobile,
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	introImg3,
	introImg3Mobile,
	setIntroImg3,
	setIntroImg3Mobile,
	setShowAboutMe,
	introImgPosition,
	introImg1Position,
	introImg2Position,
	introImg3Position,
	setIntroImgPosition,
	setIntroImg1Position,
	setIntroImg2Position,
	setIntroImg3Position,
}) => {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<section className={styles.Container2}>
			<div className={styles.Left}>
				<div className={styles.ImageMainContainer}>
					{!editable && (
						<ImageUpload
							className={styles.IntroImage}
							setImage={setIntroImage}
							setImageMobile={setIntroImageMobile}
							image={introImage}
							imageMobile={introImageMobile}
							activeId={"introImgPosition"}
							initialPosition={introImgPosition}
							setInitialPosition={setIntroImgPosition}
						/>
					)}
					{!editable && (
						<ImageUpload
							className={styles.IntroImage1}
							setImage={setIntroImg1}
							setImageMobile={setIntroImg1Mobile}
							image={introImg1}
							imageMobile={introImg1Mobile}
							activeId={"introImg1Position"}
							initialPosition={introImg1Position}
							setInitialPosition={setIntroImg1Position}
						/>
					)}
					{!editable && (
						<ImageUpload
							className={styles.IntroImage2}
							setImage={setIntroImg2}
							setImageMobile={setIntroImg2Mobile}
							image={introImg2}
							imageMobile={introImg2Mobile}
							activeId={"introImg2Position"}
							initialPosition={introImg2Position}
							setInitialPosition={setIntroImg2Position}
						/>
					)}
					{!editable && (
						<ImageUpload
							className={styles.IntroImage3}
							setImage={setIntroImg3}
							setImageMobile={setIntroImg3Mobile}
							image={introImg3}
							imageMobile={introImg3Mobile}
							activeId={"introImg3Position"}
							initialPosition={introImg3Position}
							setInitialPosition={setIntroImg3Position}
						/>
					)}

					<div className={styles.MainImage}>
						<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
							{width < 900 && height > 400 ? (
								<img src={introImageMobile} alt="" className={styles.pImg} />
							) : (
								<img src={introImage} alt="" className={styles.pImg} />
							)}
						</ImageRender>
					</div>

					<div className={`${styles.subImage1} ${styles.subImage}`}>
						<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
							{width < 900 && height > 400 ? <img src={introImg1Mobile} alt="" /> : <img src={introImg1} alt="" />}
						</ImageRender>
					</div>

					<div className={`${styles.subImage2} ${styles.subImage}`}>
						<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
							{width < 900 && height > 400 ? <img src={introImg2Mobile} alt="" /> : <img src={introImg2} alt="" />}
						</ImageRender>
					</div>

					<div className={`${styles.subImage3} ${styles.subImage}`}>
						<ImageRender initialPosition={introImg3Position} editable={editable} currentId={"introImg3Position"}>
							{width < 900 && height > 400 ? <img src={introImg3Mobile} alt="" /> : <img src={introImg3} alt="" />}
						</ImageRender>
					</div>
				</div>
			</div>

			<div className={styles.Right}>
				<h2>
					M<span>Y</span> INTRO
				</h2>
				<TextArea className={styles.p} readOnly={editable} value={intro} setValue={setIntro} />

				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}

				<div className={styles.ButtonContainer}>
					<div className={styles.button} onClick={() => setShowAboutMe(true)}>
						<h6>
							ABOUT <br /> ME
						</h6>
						<span>{">"}</span>
					</div>
					{/* <div className={styles.button}>
						<h6>
							HIRE <br /> ME
						</h6>
						<span>{">"}</span>
					</div> */}
				</div>
			</div>
		</section>
	);
};

export default Container2;
