import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ratingIcon from "../../assets/images/rating.png";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import coverFrame from "../Template3/images/coverFrame.png";
import mobileFrame from "../Template3/images/coverMobile1.png";
import style from "./Template3.module.scss";

const Container1 = ({
	editable,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	followings,
	followers,
	postCount,
	name,
	ratingData,
	setShowRating,
	coverImgPosition,
	setCoverImgPosition,
}) => {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const [widtha, setWidtha] = useState(window.innerWidth);
	const [heighta, setHeighta] = useState(window.innerHeight);
	useEffect(() => {
		function handelResize() {
			setWidtha(window.innerWidth);
			setHeighta(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [widtha, heighta]);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={style.container1}>
			<div className={style.RatingContainer} onClick={handleRating}>
				<img src={ratingIcon} alt="" />
				<span>{ratingData}</span>
			</div>
			<div className={style.coverImage}>
				<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImg"}>
					{width < 950 && height > 600 ? (
						<img src={coverImageMobile} alt="coverImageMobile" loading="lazy" />
					) : (
						<img src={coverImage} alt="coverImage" loading="lazy" />
					)}
				</ImageRender>
				{!editable && (
					<ImageUpload
						className={style.coverChangeIcon}
						setImage={setCoverImage}
						setImageMobile={setCoverImageMobile}
						image={coverImage}
						imageMobile={coverImageMobile}
						activeId={"coverImg"}
						initialPosition={coverImgPosition}
						setInitialPosition={setCoverImgPosition}
					/>
				)}
			</div>
			<div className={style.coverFrame}>
				{widtha < 950 && heighta > 600 ? (
					<img src={mobileFrame} loading="lazy" alt="" />
				) : (
					<img src={coverFrame} loading="lazy" alt="" />
				)}

				<div className={style.buttons}>
					{userId === currentUserId ? (
						<button
							className={style.follow}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<p>{followings}</p>
							Following
						</button>
					) : (
						<FollowButton className={style.follow} />
					)}
					<MessageButton className={style.message} />
				</div>
				<div className={style.followerPost}>
					{userId === currentUserId ? (
						<div
							className={style.follower}
							style={{ cursor: "pointer" }}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<h2 className="followerCount">{followers}</h2>
							<h3 className="textFollow">Followers</h3>
						</div>
					) : (
						<div className={style.follower}>
							<h2 className="followerCount">{followers}</h2>
							<h3 className="textFollow">Followers</h3>
						</div>
					)}

					<div className={style.post}>
						<h2 className="postCount">{postCount}</h2>
						<h3 className="textPost">Posts</h3>
					</div>
				</div>
				<div
					className={style.coverName}
					style={{
						fontSize: name.length > 10 ? (name.length >= 17 ? "3.6rem" : "4.5rem") : "6.4rem",
					}}>
					I'm {name}
				</div>
			</div>
		</div>
	);
};

export default Container1;
