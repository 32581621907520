import React from "react";
import { useSelector } from "react-redux";
import ratingIcon from "../../assets/images/rating.png";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template15.module.scss";
import background from "./assets/backGround.png";

function Container1({
	editable,
	name,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	followers,
	ratingData,
	setShowRating,
	coverImgPosition,
	setCoverImgPosition,
	followings,
}) {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};
	return (
		<div className={styles.Container1}>
			<div className={styles.background}>
				<img src={background} alt="" />
			</div>
			<div className={styles.heroSection}>
				<div className={styles.background}></div>
				<div className={styles.heroImage}>
					<div className={styles.firstLetter}>{name?.slice(0, 1) || "R"}</div>
					{!editable && (
						<ImageUpload
							className={styles.coverChangeIcon}
							setImage={setCoverImage}
							setImageMobile={setCoverImageMobile}
							image={coverImage}
							imageMobile={coverImageMobile}
							activeId={"coverImgPosition"}
							initialPosition={coverImgPosition}
							setInitialPosition={setCoverImgPosition}
						/>
					)}
					<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
						{width < height && width < 931 ? (
							<img src={coverImageMobile} alt="coverImageMobile" />
						) : (
							<img src={coverImage} alt="coverImage" />
						)}
					</ImageRender>
					<div className={styles.nameContainer}>
						<div className={styles.iam}>I'm</div>
						<div className={styles.firstName}>{name.split(" ")[0]}</div>
						<div className={styles.lastName}>{name.split(" ")[1]}</div>
					</div>
				</div>
				<div className={styles.actions}>
					<div className={styles.buttons}>
						{userId === currentUserId ? (
							<button
								onClick={() => {
									window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
								}}>
								<p>{followings}</p>
								Following
							</button>
						) : (
							<FollowButton />
						)}
						<MessageButton />
					</div>

					{userId === currentUserId ? (
						<div
							className={styles.followers}
							style={{ cursor: "pointer" }}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<h4>{followers}</h4>
							<h5>Followers</h5>
						</div>
					) : (
						<div className={styles.followers}>
							<h4>{followers}</h4>
							<h5>Followers</h5>
						</div>
					)}
				</div>

				<div className={styles.RatingContainer} onClick={handleRating}>
					<img src={ratingIcon} alt="" />
					<span>{ratingData}</span>
				</div>
			</div>
		</div>
	);
}

export default Container1;
