import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import product1 from "../../assets/business/image/product1.png";
import product2 from "../../assets/business/image/product2.png";
import styles from "./Template2.module.scss";
import Products from "./components/Products";
import "./Template2.scss"
import Con4MobileCorousel from "./components/Con4MobileCorousel";
import { defaultProductData } from "../../redux/store";


function Container4() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const [active, setActive] = useState(defaultProductData[0]);

	useEffect(() => {
		function handelResize() {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width, height]);

	const paddingRightVW = 20;
	const paddingRightPixel = (width * paddingRightVW) / 100;

	const responsive = {
		0: { items: 2 },
	};

	const items = defaultProductData.map((product,i) => <Products key={i} data={product} />);

	return (
		<div className={styles.Container4}>
			<div className={styles.top}>
				<h2>our product</h2>
				<p>view all</p>
			</div>
			<div className={styles.products}>
				<div className={styles.carousel} id="carouselB24">
					{width < 950 && height > 600 ? (
						<Con4MobileCorousel data={defaultProductData} active={active} setactive={setActive}/>
					) : (
						<AliceCarousel
							items={items}
							responsive={responsive}
							autoPlay
							autoPlayStrategy="none"
							autoPlayInterval={1000}
							animationDuration={1000}
							animationType="fadeout"
							paddingRight={paddingRightPixel}
							infinite
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default Container4;
