import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../../components/TextArea";
import styles from "./Template.module.scss";

const Container2 = ({
	editable,
	intro,
	setIntro,
	introImage,
	setIntroImage,
	introImageMobile,
	setIntroImageMobile,
	setShowAboutMe,
	introImgPosition,
	setIntroImgPosition,
}) => {
	const { width, height } = useSelector((state) => state.auth);
	
	return (
		<div className={styles.Container2}>
			<div className={styles.IntroImageWrapper}>
				{!editable && (
					<ImageUpload
						className={styles.IntroImageIcon}
						setImage={setIntroImage}
						image={introImage}
						setImageMobile={setIntroImageMobile}
						imageMobile={introImageMobile}
						activeId={"introImgPosition"}
						initialPosition={introImgPosition}
						setInitialPosition={setIntroImgPosition}
					/>
				)}
				<div className={styles.introImage}>
					<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
						{width < 700 && height > 400 ? (
							<img src={introImageMobile} loading="lazy" />
						) : (
							<img src={introImage} loading="lazy" />
						)}
					</ImageRender>
				</div>

				<div className={styles.introText}>
					<p>MY</p>
					<p>INTRO</p>
				</div>
			</div>

			<div className={styles.IntroTextWrapper}>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<TextArea className={styles.Text} readOnly={editable} value={intro} setValue={setIntro} />
			</div>

			<div className={styles.ButtonWrapper}>
				<button onClick={() => setShowAboutMe(true)}>About Me</button>
				{/* <button>Hire Me</button> */}
			</div>
		</div>
	);
};

export default Container2;
