import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { socket } from "../../App";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import rating from "../../assets/images/rating.png";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import axios from "../../components/Hooks/axios";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import { fetchCurrentData } from "../../redux/slice/authSlice";
import styles from "./Template2.module.scss";
import dialer from "./images/dialer.svg";
import location from "./images/location.svg";
import message from "./images/message.svg";
import store from "./images/store.svg";
import whatsapp from "./images/whatsapp.svg";

function Container1({
	editable,
	setShowRating,
	ratingData,
	businessName,
	setBusinessName,
	businessLogo,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	postCount,
	subscriberCount,
	iconData,
	companyLogos,
	coverImgPosition,
	setCoverImgPosition,
}) {
	const onContentBlur = CreateSanitizeCallback(setBusinessName);
	const { userId, user, currentUserId, currentUser ,width,height} = useSelector((state) => state.auth);
	const [followData, setFollowData] = useState("Subscribe");
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentUser === null) return;
		const result = currentUser?.followingData?.filter((id) => {
			if (id === userId) return true;
		});
		if (result?.length === 1) setFollowData("Subscribed");
		else setFollowData("Subscribe");
	}, [currentUser]);

	const handelFollow = () => {
		let sd = {
			notification_from: currentUserId,
			notification_to: userId,
			notification: {},
			title: "Subscribe",
			message: `${currentUser.displayName || currentUser.username} started subscribe you`,
		};

		const raw = JSON.stringify({
			following: userId,
		});

		axios
			.post(`/auth/follow`, raw, {
				headers: { "Content-Type": "application/json" },
			})
			.then(({ data }) => {
				console.log(data);
				if (data.message === "follow") {
					setFollowData("Subscribed");
					socket.emit("notification", sd);
				}
				if (data.message === "unfollow") setFollowData("Subscribe");
				dispatch(fetchCurrentData(currentUserId));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelMessage = () => {
		if (userId === currentUserId) {
			window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/Chat`, "_self");
		} else {
			axios.get(`/conversations/find/${userId}/${currentUserId}`).then(({ data }) => {
				if (data === null) {
					createConversations();
				} else {
					chatPageRouting();
				}
			});
		}

		const createConversations = () => {
			const raw = JSON.stringify({
				senderId: currentUserId,
				receiverId: userId,
			});

			if (user?.is_private === "Yes") {
				axios
					.post("/chat_setting/createRequest", raw, {
						headers: {
							"Content-Type": "application/json",
						},
					})
					.then(({ data }) => {
						toast.success(`Message Request Send Successfully to ${user?.username}`);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				axios
					.post("/conversations/", raw, {
						headers: {
							"Content-Type": "application/json",
						},
					})
					.then(({ data }) => {
						chatPageRouting();
					})
					.catch(({ response: { data } }) => {
						console.log(data);
					});
			}
		};

		const chatPageRouting = () => {
			axios
				.get(`/conversations/${currentUserId}`)
				.then(({ data }) => {
					data.forEach((data, index) => {
						data.data.members.filter((id) => {
							if (id === userId) {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/Chat/${index}`, "_self");
							}
						});
					});
				})
				.catch(({ response: { data } }) => {
					console.log(data);
				});
		};
	};
	return (
		<div className={styles.Container1}>
			<div className={styles.topSection}>
				<div className={styles.contents}>
					<div className={styles.logo}>
						<img src={businessLogo} alt="" />
					</div>
					<div className={styles.mid}>
						{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
						<ContentEditableDiv
							className={styles.name}
							text={businessName}
							onChange={onContentBlur}
							contentEditable={!editable}
						/>
						<div className={styles.buttons}>
							{userId === currentUserId ? (
								<button
									className={styles.subscribe}
									onClick={() => {
										window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
									}}>
									Subscribers
								</button>
							) : (
								<button onClick={handelFollow}>{followData}</button>
							)}
							<button onClick={handelMessage}>Message</button>
						</div>
					</div>

					<div className={styles.ratingSubs}>
						<div className={styles.RatingContainer} onClick={() => setShowRating(true)}>
							<img src={rating} alt="" />
							<p>{ratingData}</p>
						</div>
						<div className={styles.subscribers}>
							<div className={styles.addsub}>
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
									<g clip-path="url(#clip0_295_17157)">
										<path
											d="M14.0254 14.5052V16.5952C13.1203 16.2752 12.1517 16.177 11.2008 16.309C10.2499 16.4409 9.34459 16.7991 8.56082 17.3534C7.77705 17.9077 7.13773 18.642 6.69655 19.4946C6.25537 20.3472 6.02521 21.2932 6.02539 22.2532L4.02539 22.2522C4.02508 21.0311 4.30431 19.8261 4.84169 18.7296C5.37907 17.6331 6.16033 16.6741 7.12561 15.9262C8.09089 15.1783 9.21457 14.6613 10.4105 14.4148C11.6065 14.1683 12.8431 14.1989 14.0254 14.5042V14.5052ZM12.0254 13.2532C8.71039 13.2532 6.02539 10.5682 6.02539 7.25317C6.02539 3.93817 8.71039 1.25317 12.0254 1.25317C15.3404 1.25317 18.0254 3.93817 18.0254 7.25317C18.0254 10.5682 15.3404 13.2532 12.0254 13.2532ZM12.0254 11.2532C14.2354 11.2532 16.0254 9.46317 16.0254 7.25317C16.0254 5.04317 14.2354 3.25317 12.0254 3.25317C9.81539 3.25317 8.02539 5.04317 8.02539 7.25317C8.02539 9.46317 9.81539 11.2532 12.0254 11.2532ZM18.0254 17.2532V14.2532H20.0254V17.2532H23.0254V19.2532H20.0254V22.2532H18.0254V19.2532H15.0254V17.2532H18.0254Z"
											fill="white"
										/>
									</g>
									<defs>
										<clipPath id="clip0_295_17157">
											<rect width="24" height="24" fill="white" transform="translate(0.0253906 0.253174)" />
										</clipPath>
									</defs>
								</svg>
							</div>
							<p>{subscriberCount}</p>
							<p>subscribers</p>
						</div>
						<div className={styles.posts}>
							<div className={styles.reel}>
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
									<g clip-path="url(#clip0_295_17151)">
										<path
											d="M8.72664 3.33301C8.93365 2.74793 9.31696 2.24143 9.82379 1.88325C10.3306 1.52507 10.936 1.33283 11.5566 1.33301H13.5566C14.8626 1.33301 15.9736 2.16801 16.3866 3.33301H21.5566C21.8219 3.33301 22.0762 3.43836 22.2637 3.6259C22.4513 3.81344 22.5566 4.06779 22.5566 4.33301V20.333C22.5566 20.5982 22.4513 20.8526 22.2637 21.0401C22.0762 21.2277 21.8219 21.333 21.5566 21.333H3.55664C3.29142 21.333 3.03707 21.2277 2.84953 21.0401C2.662 20.8526 2.55664 20.5982 2.55664 20.333V4.33301C2.55664 4.06779 2.662 3.81344 2.84953 3.6259C3.03707 3.43836 3.29142 3.33301 3.55664 3.33301H8.72664ZM4.55664 5.33301V19.333H20.5566V5.33301H16.3866C16.1796 5.91808 15.7963 6.42459 15.2895 6.78277C14.7827 7.14095 14.1773 7.33319 13.5566 7.33301H11.5566C10.936 7.33319 10.3306 7.14095 9.82379 6.78277C9.31696 6.42459 8.93365 5.91808 8.72664 5.33301H4.55664ZM11.5566 3.33301C11.2914 3.33301 11.0371 3.43836 10.8495 3.6259C10.662 3.81344 10.5566 4.06779 10.5566 4.33301C10.5566 4.59822 10.662 4.85258 10.8495 5.04011C11.0371 5.22765 11.2914 5.33301 11.5566 5.33301H13.5566C13.8219 5.33301 14.0762 5.22765 14.2637 5.04011C14.4513 4.85258 14.5566 4.59822 14.5566 4.33301C14.5566 4.06779 14.4513 3.81344 14.2637 3.6259C14.0762 3.43836 13.8219 3.33301 13.5566 3.33301H11.5566ZM10.5566 9.33301L15.5566 12.333L10.5566 15.333V9.33301Z"
											fill="white"
										/>
									</g>
									<defs>
										<clipPath id="clip0_295_17151">
											<rect width="24" height="24" fill="white" transform="translate(0.556641 0.333008)" />
										</clipPath>
									</defs>
								</svg>
							</div>
							<p>{postCount}</p>
							<p>posts</p>
						</div>
					</div>
				</div>

				<div className={styles.heroImage}>
					{!editable && (
						<ImageUpload
							className={styles.landingChangeIcon}
							setImage={setCoverImage}
							setImageMobile={setCoverImageMobile}
							image={coverImage}
							imageMobile={coverImageMobile}
							activeId={"coverImgPosition"}
							initialPosition={coverImgPosition}
							setInitialPosition={setCoverImgPosition}
						/>
					)}
					<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
						{width < 931 && height > 600 ? (
							<img src={coverImageMobile} alt="coverImageMobile" />
						) : (
							<img src={coverImage} alt="coverImage" />
						)}
					</ImageRender>
					<div className={styles.socials}>
						{iconData?.whatsapp?.active && <img src={whatsapp} alt="" />}
						{iconData?.call?.active && <img src={dialer} alt="" />}
						{iconData?.email?.active && <img src={message} alt="" />}
						{iconData?.location?.active && <img src={location} alt="" />}
						{iconData?.storeUrl?.active && <img src={store} alt="" />}
					</div>
				</div>
			</div>
			<div className={styles.bottomSection}>
				{companyLogos?.active && (
					<>
						<marquee direction="left" behavior="scroll" scrollamount="10" className={styles.brand1}>
							{companyLogos?.logos.map((data, index) => (
								<img src={data} key={index} alt="logo" />
							))}
							{companyLogos?.logos.map((data, index) => (
								<img src={data} key={index} alt="logo" />
							))}
							{companyLogos?.logos.map((data, index) => (
								<img src={data} key={index} alt="logo" />
							))}
						</marquee>
					</>
				)}
			</div>
		</div>
	);
}

export default Container1;
