import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Btemplate7.module.scss";

function Container2({ editable, aboutusImg, setAboutusImg, aboutusText, setAboutusText }) {
	const onContentBlur = CreateSanitizeCallback(setAboutusText);
	return (
		<div className={styles.Container2}>
			<div className={styles.leftSection}>
				<div className={styles.aboutusImage}>
					{!editable && <ImageUpload className={styles.aboutusChangeIcon} setImage={setAboutusImg} image={aboutusImg} />}
					<img src={aboutusImg} alt="" />
					<div className={styles.yellowBox}></div>
				</div>
			</div>
			<div className={styles.rightSection}>
				<h2>About Company</h2>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<ContentEditableDiv
					className={styles.aboutusText}
					text={aboutusText}
					onChange={onContentBlur}
					contentEditable={!editable}
				/>
				<button>We're Hiring</button>
			</div>
		</div>
	);
}

export default Container2;
