import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import axios from "../../components/Hooks/axios";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import { defaultPostData } from "../../redux/store";
import style from "./Template6.module.scss";
import TextArea from "./TextArea";

function Container3({ editable, galleryText, setGalleryText }) {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data.length === 0) setPostData(defaultPostData);
				else setPostData(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	const SmallGallery = ({ data, index }) => {
		if (data.mediaType === "image")
			return (
				<div className={style.smallContainer} onClick={() => setActiveIndex(index)}>
					<img src={data.mediaUrl[0]} alt="" />
				</div>
			);
	};

	const handleGallery = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		navigate("/Gallery");
	};

	return (
		<div className={style.container3}>
			<div className={style.galleryContainer}>
				<div className={style.galleryLeft}>
					<div className={style.galleryHero}>
						<img src={postData[activeIndex]?.mediaUrl[0]} alt="gallery1" />
					</div>
					<div className={style.heroLeft}>
						{activeIndex === 0 ? (
							postData.length <= 11 ? (
								<img src={postData[postData.length - 1]?.mediaUrl[0]} alt="gallery2" />
							) : (
								<img src={postData[11]?.mediaUrl[0]} alt="gallery2" />
							)
						) : (
							<img src={postData[activeIndex - 1]?.mediaUrl[0]} alt="gallery2" />
						)}
					</div>
					<div className={style.heroRight}>
						{activeIndex === 11 ? (
							<img src={postData[0]?.mediaUrl[0]} alt="gallery3" />
						) : postData.length === activeIndex + 1 ? (
							<img src={postData[0]?.mediaUrl[0]} alt="gallery3" />
						) : (
							<img src={postData[activeIndex + 1]?.mediaUrl[0]} alt="gallery3" />
						)}
					</div>
				</div>

				<div className={style.galleryRight}>
					<div className={style.gallery}>
						{postData.slice(0, 12).map((data, i) => {
							return <SmallGallery data={data} index={i} key={i} />;
						})}
					</div>
					<div className={style.galleryText}>
						<div className={style.myGallery}>My Gallery</div>
						{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
						<TextArea className={style.galleryDesc} readOnly={editable} value={galleryText} setValue={setGalleryText} />
					</div>
					<div className={style.ViewAll}>
						<p onClick={handleGallery}>View All {">"}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Container3;
