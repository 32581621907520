import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template5.module.scss";
import frame from "./images/finalFrame.png";
import mobileFrame from "./images/mobile.png";

function Container1({
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	coverImgPosition,
	setCoverImgPosition,
	followers,
	setShowRating,
	editable,
	name,
	setName,
	ratingData,
	followings,
}) {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const [angle, setAngle] = useState(0);
	const scalingFactor = 0.8;

	useEffect(() => {
		function adjustRotation() {
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;
			const newAngle = Math.atan(viewportHeight / viewportWidth) * (180 / Math.PI);
			const scaledAngle = newAngle * scalingFactor;
			setAngle(scaledAngle - 10);
			if (viewportWidth <= 1950) {
				setAngle(scaledAngle - 6.1);
			}
			if (viewportWidth <= 1420) {
				setAngle(scaledAngle - 5);
			}

			if (viewportWidth <= 1280) {
				setAngle(scaledAngle - 6.6);
			}
			if (viewportWidth <= 1280 && viewportHeight <= 577) {
				setAngle(scaledAngle - 5.3);
			}
			if (viewportWidth <= 1080) {
				setAngle(scaledAngle - 22.1);
			}
			if (viewportWidth <= 1080 && viewportHeight < 500) {
				setAngle(scaledAngle - 5.6);
			}
			// if (viewportWidth <= 850) {
			// 	setAngle(scaledAngle - 22.2);
			// }
			if (viewportWidth <= 490) {
				setAngle(scaledAngle - 20.5);
			}
			if (viewportWidth <= 390) {
				setAngle(scaledAngle - 21.5);
			}
		}
		adjustRotation();

		// Listen for window resize events and adjust rotation accordingly
		window.addEventListener("resize", adjustRotation);

		return () => {
			window.removeEventListener("resize", adjustRotation);
		};
	}, []);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={style.container1}>
			<div className={style.star} onClick={handleRating}>
				{ratingData}
			</div>
			{userId === currentUserId ? (
				<div
					className={style.follower}
					style={{ cursor: "pointer" }}
					onClick={() => {
						window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
					}}>
					<div className={style.followerCount}>{followers ? followers : 0}</div>
					<div className={style.textFollower}>Followers</div>
				</div>
			) : (
				<div className={style.follower}>
					<div className={style.followerCount}>{followers ? followers : 0}</div>
					<div className={style.textFollower}>Followers</div>
				</div>
			)}
			<MessageButton className={style.message}/>
			{userId === currentUserId ? (
				<button
					className={style.follow}
					onClick={() => {
						window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
					}}>
					<p>{followings ? followings : 0}</p>
					Following
				</button>
			) : (
				<FollowButton className={style.follow} />
			)}
			<div className={style.nameFrame} id="dynamicRotate" style={{ transform: `rotate(-${angle}deg)` }}>
				<div
					className={style.coverName}
					onChange={(e) => setName(e.target.value)}
					style={{ fontSize: name.length > 17 ? (name.length > 25 ? "2.5rem" : "3rem") : "3.5rem" }}>
					I'm {name}
				</div>
			</div>

			<div className={style.coverImage}>
				<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImg"}>
					{width < 950 && height > 600 ? (
						<img src={coverImageMobile} alt="coverImageMobile" loading="lazy" />
					) : (
						<img src={coverImage} alt="coverImage" />
					)}
				</ImageRender>
			</div>

			{!editable && (
				<ImageUpload
					className={style.coverChangeIcon}
					setImage={setCoverImage}
					setImageMobile={setCoverImageMobile}
					image={coverImage}
					imageMobile={coverImageMobile}
					activeId={"coverImg"}
					initialPosition={coverImgPosition}
					setInitialPosition={setCoverImgPosition}
				/>
			)}
			<div className={style.frame}>
				<img src={frame} alt="" className={style.desktopFrame} />
				<img src={mobileFrame} alt="" className={style.mobileFrame} />
			</div>
		</div>
	);
}

export default Container1;
