import React from "react";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template5.module.scss";
import galleryFrame from "./images/galleryFrame.png";
import mobileFrame from "./images/galleryMobile.png";
import { useSelector } from "react-redux";

function Container3({
	editable,
	introImage2,
	introImage2Mobile,
	setIntroImage2,
	setIntroImage2Mobile,
	introImg2Position,
	setIntroImg2Position,
}) {
	const isMobile = window.innerWidth < 931 && window.innerHeight > 599;
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.container3}>
			<div className={styles.galleryImage}>
				<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"GalleryImg"}>
					{width < 950 && height > 600 ? (
						<img src={introImage2Mobile} alt="introImage2Mobile" />
					) : (
						<img src={introImage2} alt="introImage2" />
					)}
				</ImageRender>
			</div>

			<div className={styles.frame}>
				<img src={isMobile ? mobileFrame : galleryFrame} alt="" />
			</div>
			<div className={styles.border}></div>
			{!editable && (
				<ImageUpload
					className={styles.intro2ChangeIcon}
					setImage={setIntroImage2}
					setImageMobile={setIntroImage2Mobile}
					image={introImage2}
					imageMobile={introImage2Mobile}
					activeId={"GalleryImg"}
					initialPosition={introImg2Position}
					setInitialPosition={setIntroImg2Position}
				/>
			)}
		</div>
	);
}

export default Container3;
