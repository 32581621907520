import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template6.module.scss";
import TextArea from "./TextArea";
import ImageRender from "../../components/EditingTool/ImageRender";
import { useSelector } from "react-redux";

function Container2({
	editable,
	intro,
	setIntro,
	introImage,
	introImageMobile,
	setIntroImage,
	setIntroImageMobile,
	setShowAboutMe,
	introImgPosition,
	setIntroImgPosition,
}) {
	const { width, height } = useSelector((state) => state.auth);

	return (
		<div className={style.container2}>
			<div className={style.hero}>
				{!editable && (
					<ImageUpload
						className={style.IntroImage}
						setImage={setIntroImage}
						setImageMobile={setIntroImageMobile}
						image={introImage}
						imageMobile={introImageMobile}
						activeId={"introImgPosition"}
						initialPosition={introImgPosition}
						setInitialPosition={setIntroImgPosition}
					/>
				)}
				<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
					{width < 950 && height > 600 ? (
						<img src={introImageMobile} alt="introImageMobile" />
					) : (
						<img src={introImage} alt="introImage" />
					)}
				</ImageRender>
				<div className={style.bottomStructure}></div>
			</div>
			<div className={style.introFooter}>
				<div className={style.introTitle}>My Intro</div>
				{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
				<TextArea className={style.introDescription} readOnly={editable} value={intro} setValue={setIntro} />
				<div className={style.introButtons}>
					<div className={style.buttonContainer}>
						<button onClick={() => setShowAboutMe(true)}>About Me</button>
					</div>
					{/* <div className={style.buttonContainer}>
						<button>Hire Me</button>
					</div> */}
				</div>
			</div>
		</div>
	);
}

export default Container2;
