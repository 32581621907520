import React from "react";
import comment from "../../../assets/business/image/comment.svg";
import share from "../../../assets/business/image/share.svg";
import LikeBtn from "../../../components/LikeButton/LikeBtn";
import styles from "../Template15.module.scss";
import "../Template15.scss";

function GalleryCard({ product }) {
	return (
		<div className={styles.galleryCard}>
			<div className={styles.backImage}>
				{/* <img src={product.mediaUrl} alt="" /> */}
				{product?.mediaType === "video" ? (
					<video src={product?.mediaUrl[0]} controls></video>
				) : product?.mediaType === "image" ? (
					<img src={product?.mediaUrl[0]} alt="" />
				) : (
					""
				)}
			</div>
			<div className={styles.profile} id="profile">
				<div className={styles.profilePic}>
					<img src={product.userId.ProfilePic} alt="" />
				</div>
				<div className={styles.detail}>
					<h3>{product.userId.displayName}</h3>
					<h4>{product.userId.GetstatedName}</h4>
				</div>
			</div>
			<div className={styles.details} id="details">
				<div className={styles.desc}>{product.postDesc}</div>
				<div className={styles.actions}>
					<div className={styles.like}>
						<LikeBtn data={product} Icon={styles.likeIcon} LikeIcon={styles.LikeIcon} Text={styles.likeCount} />
					</div>
					<div className={styles.comment}>
						<img src={comment} alt="" />
						<p>{product.Comments.length}</p>
					</div>
					<div className={styles.share}>
						<img src={share} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default GalleryCard;
