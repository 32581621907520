import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import axios from "../../components/Hooks/axios";
import { defaultPostData } from "../../redux/store";
import styles from "./Template10.module.scss";
import TextArea from "./TextArea";
import CommentIcon from "./images/CommentIcon.svg";
import ShareIcon from "./images/ShareIcon.svg";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import LikeBtn from "../../components/LikeButton/LikeBtn";

const Container4 = ({ editable, shortStory, setShortStory }) => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);

	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data.length === 0) setPostData(defaultPostData);
				else setPostData(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	return (
		<>
			<div className={styles.Con4Bg}>
				<div className={styles.top}>
					<div className={styles.topText1}>My Gallery</div>
					<div className={styles.topText2} onClick={() => navigate("/Gallery")}>
						View All
					</div>
				</div>
				<div className={styles.middle}>
					<div className={styles.sliderContainer}>
						<Swiper
							effect={"coverflow"}
							grabCursor={true}
							centeredSlides={false}
							loop={false}
							slidesPerView={1}
							spaceBetween={30}
							pagination={{
								el: ".swiper-pagination",
								clickable: true,
								bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`,
							}}
							autoplay={{ delay: 3000, disableOnInteraction: false }}
							modules={[Pagination, Autoplay]}
							className="swiper_container">
							{postData?.slice(0, 5).map((data, i) => {
								return (
									<SwiperSlide key={i}>
										{data?.mediaType === "video" ? (
											<video src={data?.mediaUrl[0]} className={styles.imageSlider} controls></video>
										) : data?.mediaType === "image" ? (
											<img src={data?.mediaUrl[0]} alt="slide_image" className={styles.imageSlider} />
										) : (
											""
										)}

										<div className={styles.ImageFooter}>
											<div className={styles.LeftPart}>{data?.caption}</div>
											<div className={styles.RightPart}>
												{/* <div className={styles.Iconcontainer}>
												<img className={styles.styleiconLike} src={LikeIcon} alt="Like" />
												<p className={styles.count}>05</p>
											</div> */}
												<LikeBtn
													data={data}
													Icon={styles.Iconcontainer}
													LikeIcon={styles.styleiconLike}
													Text={styles.count}
												/>
												<div className={styles.Iconcontainer}>
													<img className={styles.styleiconComment} src={CommentIcon} alt="Comment" />
													<p className={styles.count}>{data?.Comments?.length}</p>
												</div>
												<div className={styles.Iconcontainer}>
													<img className={styles.styleiconShare} src={ShareIcon} alt="Share" />
												</div>
											</div>
										</div>
									</SwiperSlide>
								);
							})}

							<div className={styles.bulletheight}>
								<div className="slider-controler">
									<div className="swiper-pagination"></div>
								</div>
							</div>
						</Swiper>
					</div>
				</div>
				<div className={styles.bottom}>
					{!editable && <img src={EditTextIcon} alt="" className={styles.ShortEditTextIconM} />}
					<TextArea className={styles.footerText1} readOnly={editable} value={shortStory} setValue={setShortStory} />
					<div className={styles.footerText2}>Thank you ! Visit Again</div>
				</div>
			</div>
		</>
	);
};
export default Container4;
