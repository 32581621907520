import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ratingIcon from "../../assets/images/rating.png";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../Template1/TextArea";
import styles from "./Template14.module.scss";

function Container1({
	editable,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	coverText,
	setCoverText,
	name,
	ratingData,
	setShowRating,
	followings,
	followers,
	coverImgPosition,
	setCoverImgPosition,
}) {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};
	return (
		<div className={styles.Container1}>
			<div className={styles.heroImage}>
				<div className={styles.NameMobile}>{name.split(" ")[0]}</div>
				<div className={styles.dot3Mob}></div>
				{!editable && (
					<ImageUpload
						className={styles.coverChangeIcon}
						setImage={setCoverImage}
						setImageMobile={setCoverImageMobile}
						image={coverImage}
						imageMobile={coverImageMobile}
						activeId={"coverImgPosition"}
						initialPosition={coverImgPosition}
						setInitialPosition={setCoverImgPosition}
					/>
				)}
				<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
					{width < height && width < 931 ? (
						<img src={coverImageMobile} alt="coverImageMobile" />
					) : (
						<img src={coverImage} alt="coverImage" />
					)}
				</ImageRender>
				<div className={styles.follow}>
					{userId === currentUserId ? (
						<button
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<p>{followings}</p>
							Following
						</button>
					) : (
						<FollowButton />
					)}
				</div>
				<div className={styles.message}>
					<MessageButton />
				</div>
				<div className={styles.details}>
					<div className={styles.fullName}>
						<div className={styles.Iam}>I'm</div>
						<div className={styles.name}>
							{name.split(" ")[0]} <span>{name.split(" ")[1]}</span>
						</div>
					</div>

					<div className={styles.TextWrapper}>
						{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
						<TextArea className={styles.coverText} readOnly={editable} value={coverText} setValue={setCoverText} />
					</div>
				</div>
			</div>
			<div className={styles.dot1}></div>
			<div className={styles.dot2}></div>
			<div className={styles.dot3}></div>
			<div className={styles.dot4}></div>
			<div className={styles.dot1Mob}></div>
			<div className={styles.dot2Mob}></div>
			<div className={styles.ratingFollower}>
				<div className={styles.RatingContainer} onClick={handleRating}>
					<img src={ratingIcon} alt="" />
					<span>{ratingData}</span>
				</div>

				{userId === currentUserId ? (
					<div
						className={styles.followers}
						style={{ cursor: "pointer" }}
						onClick={() => {
							window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
						}}>
						<h3>{followers}</h3>
						<h4>Followers</h4>
					</div>
				) : (
					<div className={styles.followers}>
						<h3>{followers}</h3>
						<h4>Followers</h4>
					</div>
				)}
			</div>
		</div>
	);
}

export default Container1;
