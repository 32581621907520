import React, { useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../components/Hooks/axios";
import styles from "./Template1.module.scss";
import CommentIcon from "./images/CommentIcon.svg";
import ShareIcon from "./images/ShareIcon.svg";
// import HeartFilled from "./images/heartFill.svg";
// import HeartUnfilled from "./images/HeartUnfilled.svg";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import LikeBtn from "../../components/LikeButton/LikeBtn";
import { defaultPostData } from "../../redux/store";

const Container3 = () => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);

	const [index, setIndex] = useState(0);
	const [mouseOver, setMouseOver] = useState(false);
	const post1Ref = useRef();
	const post2Ref = useRef();
	const post3Ref = useRef();
	const post4Ref = useRef();
	const post5Ref = useRef();

	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				console.log(data);
				if (data.length === 0) setPostData(defaultPostData);
				else setPostData(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	useEffect(() => {
		if (index === 0 && postData[0]) post1Ref?.current?.click();
		if (index === 1 && postData[1]) post2Ref?.current?.click();
		if (index === 2 && postData[2]) post3Ref?.current?.click();
		if (index === 3 && postData[3]) post4Ref?.current?.click();
		if (index === 4 && postData[4]) post5Ref?.current?.click();

		const currentIndex = setInterval(() => {
			if (index < 5) if (!mouseOver) if (postData.length > 1) setIndex(index + 1);
		}, 5000);

		setTimeout(() => {
			if (index === postData?.length - 1 || index === 4) setIndex(0);
		}, 5000);

		return () => clearInterval(currentIndex);
	}, [index, mouseOver, postData]);

	const handleGallery = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		navigate("/Gallery");
	};

	return (
		<div className={styles.Container3}>
			<h1>My Gallery</h1>

			<div className={styles.bar}>
				<h3 onClick={handleGallery}>View All</h3>

				<div className={styles.BtnContainer}>
					<button
						onClick={() => {
							if (index > 0) setIndex(index - 1);
						}}>
						<FiArrowLeft />
					</button>
					<button
						onClick={() => {
							if (index < 5) setIndex(index + 1);
						}}>
						<FiArrowRight />
					</button>
				</div>
			</div>

			<div className={styles.CarouselWrapper}>
				<div className={styles.CarouselContainer}>
					{postData[0] && <input type="radio" name="btn" id="post1" onClick={() => setIndex(0)} ref={post1Ref} />}
					{postData[1] && <input type="radio" name="btn" id="post2" onClick={() => setIndex(1)} ref={post2Ref} />}
					{postData[2] && <input type="radio" name="btn" id="post3" onClick={() => setIndex(2)} ref={post3Ref} />}
					{postData[3] && <input type="radio" name="btn" id="post4" onClick={() => setIndex(3)} ref={post4Ref} />}
					{postData[4] && <input type="radio" name="btn" id="post5" onClick={() => setIndex(4)} ref={post5Ref} />}

					{postData?.slice(0, 5).map((data, i) => {
						return (
							<div
								key={i}
								className={index === i ? styles.ActiveImageContainer : styles.ImageContainer}
								id={i === 0 ? "ActiveImageContainer" : ""}
								onMouseEnter={() => {
									if (index === i) setMouseOver(true);
								}}
								onMouseLeave={() => setMouseOver(false)}>
								{data?.mediaType === "video" ? (
									<video src={data?.mediaUrl[0]} controls></video>
								) : data?.mediaType === "image" ? (
									<img src={data?.mediaUrl[0]} alt="" />
								) : (
									""
								)}

								<div className={index === i ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
									<div className={styles.Left}>{data?.caption}</div>
									<div className={styles.Right}>
										{/* <div className={styles.Icon} onClick={handelLike}>
											{isLiked ? (
												<img src={HeartFilled} alt="" className={styles.LikeIcon} />
											) : (
												<img src={HeartUnfilled} alt="" className={styles.LikeIcon} />
											)}
											<p>{data.LikeCount}</p>
										</div> */}
										<LikeBtn data={data} Icon={styles.Icon} LikeIcon={styles.LikeIcon} />
										<div className={styles.Icon}>
											<img src={CommentIcon} alt="Comment" className={styles.c} />
											<p>{data?.Comments?.length}</p>
										</div>
										<div className={styles.Icon}>
											<img src={ShareIcon} alt="Share" />
										</div>
									</div>
								</div>
							</div>
						);
					})}

					{/* {postData[0] && (
						<div
							className={index === 1 ? styles.ActiveImageContainer : styles.ImageContainer}
							id="ActiveImageContainer"
							onMouseEnter={() => {
								if (index === 1) setMouseOver(true);
							}}
							onMouseLeave={() => setMouseOver(false)}>
							{postData[0]?.mediaType === "video" ? (
								<video src={postData[0]?.mediaUrl} controls></video>
							) : postData[0]?.mediaType === "image" ? (
								<img src={postData[0]?.mediaUrl} alt="" />
							) : (
								""
							)}

							<div className={index === 1 ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
								<div className={styles.Left}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
								<div className={styles.Right}>
									<div className={styles.Icon}>
										<img src={LikeIcon} alt="Like" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={CommentIcon} alt="Comment" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={ShareIcon} alt="Share" />
									</div>
								</div>
							</div>
						</div>
					)}

					{postData[1] && (
						<div
							className={index === 2 ? styles.ActiveImageContainer : styles.ImageContainer}
							onMouseEnter={() => {
								if (index === 2) setMouseOver(true);
							}}
							onMouseLeave={() => setMouseOver(false)}>
							{postData[1]?.mediaType === "video" ? (
								<video src={postData[1]?.mediaUrl} controls></video>
							) : postData[1]?.mediaType === "image" ? (
								<img src={postData[1]?.mediaUrl} alt="" />
							) : (
								""
							)}

							<div className={index === 2 ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
								<div className={styles.Left}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
								<div className={styles.Right}>
									<div className={styles.Icon}>
										<img src={LikeIcon} alt="Like" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={CommentIcon} alt="Comment" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={ShareIcon} alt="Share" />
									</div>
								</div>
							</div>
						</div>
					)}

					{postData[2] && (
						<div
							className={index === 3 ? styles.ActiveImageContainer : styles.ImageContainer}
							onMouseEnter={() => {
								if (index === 3) setMouseOver(true);
							}}
							onMouseLeave={() => setMouseOver(false)}>
							{postData[2]?.mediaType === "video" ? (
								<video src={postData[2]?.mediaUrl} controls></video>
							) : postData[2]?.mediaType === "image" ? (
								<img src={postData[2]?.mediaUrl} alt="" />
							) : (
								""
							)}

							<div className={index === 3 ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
								<div className={styles.Left}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
								<div className={styles.Right}>
									<div className={styles.Icon}>
										<img src={LikeIcon} alt="Like" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={CommentIcon} alt="Comment" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={ShareIcon} alt="Share" />
									</div>
								</div>
							</div>
						</div>
					)}

					{postData[4] && (
						<div
							className={index === 4 ? styles.ActiveImageContainer : styles.ImageContainer}
							onMouseEnter={() => {
								if (index === 4) setMouseOver(true);
							}}
							onMouseLeave={() => setMouseOver(false)}>
							{postData[4]?.mediaType === "video" ? (
								<video src={postData[4]?.mediaUrl} controls></video>
							) : postData[4]?.mediaType === "image" ? (
								<img src={postData[4]?.mediaUrl} alt="" />
							) : (
								""
							)}

							<div className={index === 4 ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
								<div className={styles.Left}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
								<div className={styles.Right}>
									<div className={styles.Icon}>
										<img src={LikeIcon} alt="Like" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={CommentIcon} alt="Comment" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={ShareIcon} alt="Share" />
									</div>
								</div>
							</div>
						</div>
					)}

					{postData[5] && (
						<div
							className={index === 5 ? styles.ActiveImageContainer : styles.ImageContainer}
							onMouseEnter={() => {
								if (index === 5) setMouseOver(true);
							}}
							onMouseLeave={() => setMouseOver(false)}>
							{postData[5]?.mediaType === "video" ? (
								<video src={postData[5]?.mediaUrl} controls></video>
							) : postData[5]?.mediaType === "image" ? (
								<img src={postData[5]?.mediaUrl} alt="" />
							) : (
								""
							)}

							<div className={index === 5 ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
								<div className={styles.Left}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
								<div className={styles.Right}>
									<div className={styles.Icon}>
										<img src={LikeIcon} alt="Like" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={CommentIcon} alt="Comment" />
										<p>05</p>
									</div>
									<div className={styles.Icon}>
										<img src={ShareIcon} alt="Share" />
									</div>
								</div>
							</div>
						</div>
					)} */}
				</div>
			</div>
		</div>
	);
};

export default Container3;
