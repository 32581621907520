import { NavLink } from "react-router-dom";

const WebPage = () => {
	document.documentElement.style.setProperty("--base-font-size", "100%");

	return (
		<>
			<NavLink to="/64e369b0c5b49890c7da330d">
				<h1>Template 1</h1>
			</NavLink>
			<NavLink to="/Template2">
				<h1>Template 2</h1>
			</NavLink>
			<NavLink to="/64ec9d9bb9b30c1ed4b48bcc">
				<h1>Template 3</h1>
			</NavLink>
			<NavLink to="/Template4">
				<h1>Template 4</h1>
			</NavLink>
			<NavLink to="/Template5">
				<h1>Template 5</h1>
			</NavLink>
			<NavLink to="/Template6">
				<h1>Template 6</h1>
			</NavLink>
			<NavLink to="/Template7">
				<h1>Template 7</h1>
			</NavLink>
			<NavLink to="/64eca015b9b30c1ed4b48c13">
				<h1>Template 8</h1>
			</NavLink>
			<NavLink to="/Template9">
				<h1>Template 9</h1>
			</NavLink>
			<NavLink to="/Template10">
				<h1>Template 10</h1>
			</NavLink>
			<NavLink to="/Template11">
				<h1>Template 11</h1>
			</NavLink>
			<NavLink to="/Template12">
				<h1>Template 12</h1>
			</NavLink>
			<NavLink to="/Template13">
				<h1>Template 13</h1>
			</NavLink>
			<NavLink to="/Template14">
				<h1>Template 14</h1>
			</NavLink>
			<NavLink to="/Template15">
				<h1>Template 15</h1>
			</NavLink>
		</>
	);
};
export default WebPage;
