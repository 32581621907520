import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import TextArea from "../../components/TextArea";
import styles from "./Template.module.scss";

const Container4 = ({ editable, shortStory, setShortStory }) => {
	const { userId, currentUserId } = useSelector((s) => s.auth);
	return (
		<div className={styles.Container4}>
			{userId === currentUserId && <h1>SHORT MESSAGE</h1>}
			{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
			<TextArea className={styles.ShortSMS} readOnly={editable} value={shortStory} setValue={setShortStory} />
			<h4>Thank you ! Visit Again</h4>
		</div>
	);
};
export default Container4;
