import React from "react";
import styles from "./Btemplate7.module.scss";
import product1 from "./assets/product1.png";
import product2 from "./assets/product2.png";
import product3 from "./assets/product3.png";
import product4 from "./assets/product4.png";
import product5 from "./assets/product5.png";
import product6 from "./assets/product6.png";
import comment from "../../assets/business/image/comment.svg";
import heart from "../../assets/business/image/heartLike.svg";
import share from "../../assets/business/image/share.svg";

const products = [
	{
		id: 0,
		mediaUrl: product1,
		productName: "Hp Laptop | 1TB | i7 processor",
	},
	{
		id: 1,
		mediaUrl: product2,
		productName: "Vintage Rollefix Camera",
	},
	{
		id: 2,
		mediaUrl: product3,
		productName: "Oppo XR | 128GB",
	},
	{
		id: 3,
		mediaUrl: product4,
		productName: "Mini Fuji Camera",
	},
	{
		id: 4,
		mediaUrl: product5,
		productName: "Fuji Mirrorless Camera",
	},
	{
		id: 5,
		mediaUrl: product6,
		productName: "Fuji Mirror less Camera",
	},
];

function Container4() {
	return (
		<div className={styles.Container4}>
			<div className={styles.topSection}>
				<h2>Our Product</h2>
				<p>View All</p>
			</div>
			<div className={styles.products}>
				{products &&
					products.map((product) => (
						<div className={styles.productCard}>
							<img src={product.mediaUrl} alt="" />
							<h3>{product.productName}</h3>
							<div className={styles.actions}>
								<div className={styles.like}>
									<img src={heart} alt="" />
								</div>
								<div className={styles.comment}>
									<img src={comment} alt="" />
								</div>
								<div className={styles.share}>
									<img src={share} alt="" />
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
}

export default Container4;
