import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template12.module.scss";

function Container2({
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	introImg3,
	introImg3Mobile,
	setIntroImg3,
	setIntroImg3Mobile,
	introImg4,
	introImg4Mobile,
	setIntroImg4,
	setIntroImg4Mobile,
	introImg5,
	introImg5Mobile,
	setIntroImg5,
	setIntroImg5Mobile,
	introText,
	setIntroText,
	editable,
	setShowAboutMe,
	introImg1Position,
	setIntroImg1Position,
	introImg2Position,
	setIntroImg2Position,
	introImg3Position,
	setIntroImg3Position,
	introImg4Position,
	setIntroImg4Position,
	introImg5Position,
	setIntroImg5Position,
}) {
	const { width, height } = useSelector((state) => state.auth);
	const onContentBlur = CreateSanitizeCallback(setIntroText);
	return (
		<div className={style.Container2}>
			<div className={style.leftSection}>
				<div className={style.imgContainer}>
					<div className={style.introImg1}>
						{!editable && (
							<ImageUpload
								className={style.intro1ChangeIcon}
								setImage={setIntroImg1}
								setImageMobile={setIntroImg1Mobile}
								image={introImg1}
								imageMobile={introImg1Mobile}
								activeId={"introImg1Position"}
								initialPosition={introImg1Position}
								setInitialPosition={setIntroImg1Position}
							/>
						)}
						<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
							{width < 931 && height > 600 ? (
								<img src={introImg1Mobile} alt="introImg1Mobile" />
							) : (
								<img src={introImg1} alt="introImg1" />
							)}
						</ImageRender>

						<div className={style.overlay}></div>
					</div>
					<div className={style.introImg2}>
						{!editable && (
							<ImageUpload
								className={style.intro2ChangeIcon}
								image={introImg2}
								imageMobile={introImg2Mobile}
								setImage={setIntroImg2}
								setImageMobile={setIntroImg2Mobile}
								activeId={"introImg2Position"}
								initialPosition={introImg2Position}
								setInitialPosition={setIntroImg2Position}
							/>
						)}

						<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
							{width < 931 && height > 600 ? (
								<img src={introImg2Mobile} alt="introImg2Mobile" />
							) : (
								<img src={introImg2} alt="introImg2" />
							)}
						</ImageRender>
					</div>
				</div>
			</div>

			<div className={style.rightSection}>
				<div className={style.TextWrapper}>
					{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
					<ContentEditableDiv className={style.introText} text={introText} onChange={onContentBlur} contentEditable={!editable} />
				</div>

				<div className={style.buttons}>
					<button onClick={() => setShowAboutMe(true)}>About Me</button>
					{/* <button>Hire me</button> */}
				</div>

				<div className={style.introImages}>
					<div className={style.introImg3}>
						{!editable && (
							<ImageUpload
								className={style.intro3ChangeIcon}
								image={introImg3}
								imageMobile={introImg3Mobile}
								setImage={setIntroImg3}
								setImageMobile={setIntroImg3Mobile}
								activeId={"introImg3Position"}
								initialPosition={introImg3Position}
								setInitialPosition={setIntroImg3Position}
							/>
						)}

						<ImageRender initialPosition={introImg3Position} editable={editable} currentId={"introImg3Position"}>
							{width < 931 && height > 600 ? (
								<img src={introImg3Mobile} alt="introImg3Mobile" />
							) : (
								<img src={introImg3} alt="introImg3" />
							)}
						</ImageRender>
					</div>
					<div className={style.introImg4}>
						{!editable && (
							<ImageUpload
								className={style.intro4ChangeIcon}
								image={introImg4}
								imageMobile={introImg4Mobile}
								setImage={setIntroImg4}
								setImageMobile={setIntroImg4Mobile}
								activeId={"introImg4Position"}
								initialPosition={introImg4Position}
								setInitialPosition={setIntroImg4Position}
							/>
						)}
						<ImageRender initialPosition={introImg4Position} editable={editable} currentId={"introImg4Position"}>
							{width < 931 && height > 600 ? (
								<img src={introImg4Mobile} alt="introImg4Mobile" />
							) : (
								<img src={introImg4} alt="introImg4" />
							)}
						</ImageRender>
					</div>
					<div className={style.introImg5}>
						{!editable && (
							<ImageUpload
								className={style.intro5ChangeIcon}
								image={introImg5}
								imageMobile={introImg5Mobile}
								setImage={setIntroImg5}
								setImageMobile={setIntroImg5Mobile}
								activeId={"introImg5Position"}
								initialPosition={introImg5Position}
								setInitialPosition={setIntroImg5Position}
							/>
						)}

						<ImageRender initialPosition={introImg5Position} editable={editable} currentId={"introImg5Position"}>
							{width < 931 && height > 600 ? (
								<img src={introImg5Mobile} alt="introImg5Mobile" />
							) : (
								<img src={introImg5} alt="introImg5" />
							)}
						</ImageRender>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Container2;
