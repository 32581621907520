import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import axios from "../../components/Hooks/axios";
import { defaultPostData } from "../../redux/store";
import TextArea from "../Template1/TextArea";
import styles from "./Template15.module.scss";
import background from "./assets/backGround.png";
import GalleryCard from "./components/GalleryCard";
import MobileGallery from "./components/MobileGallery";

function Container4({ editable, shortMessage, setShortMessage }) {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);
	const items = postData?.map((post) => <GalleryCard key={post.id} product={post} />);

	const responsive = {
		0: { items: 1 },
	};
	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data.length === 0) setPostData(defaultPostData);
				else setPostData(data);
				console.log(defaultPostData);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	useEffect(() => {
		function handelResize() {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width, height]);

	return (
		<div className={styles.Container4}>
			<div className={styles.background}>
				<img src={background} alt="" />
			</div>
			<div className={styles.content}>
				<h2>My Gallery</h2>
				<div className={styles.carousel} id="carousel15">
					{width < height ? (
						<MobileGallery posts={postData} />
					) : (
						<AliceCarousel
							items={items}
							responsive={responsive}
							autoPlay
							autoPlayInterval={1000}
							animationDuration={1000}
							infinite
						/>
					)}
				</div>
				<div className={styles.shortMessage}>
					{!editable && <h3>Short Message</h3>}

					{/* <div className={styles.message}>{shortMessage}</div> */}
					<div className={styles.TextWrapper}>
						{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
						<TextArea className={styles.message} readOnly={editable} value={shortMessage} setValue={setShortMessage} />
					</div>
				</div>
				<div className={styles.thankYou}>Thank you ! Visit Again</div>
			</div>
		</div>
	);
}

export default Container4;
