import React from "react";
import comment from "../../../assets/business/image/comment.svg";
import heart from "../../../assets/business/image/heartLike.svg";
import share from "../../../assets/business/image/share.svg";
import styles from "../Template2.module.scss";

function Products({ data }) {
	return (
		<div className={styles.ProductContainer}>
			<img src={data.mediaUrl} alt="" />
			<h2>{data.productName}</h2>
			<div className={styles.actions}>
				<div className={styles.heart}>
					<img src={heart} alt="" />
				</div>
				<div className={styles.comment}>
					<img src={comment} alt="" />
				</div>
				<div className={styles.share}>
					<img src={share} alt="" />
				</div>
			</div>
		</div>
	);
}

export default Products;
