import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template3.module.scss";
import dot from "./images/dots.png";
import introFrame from "./images/introFrame.png";
import mobileFrame from "./images/introMobile.png";

const Container3 = ({
	introImage,
	introImageMobile,
	setIntroImage,
	setIntroImageMobile,
	editable,
	introText,
	setIntroText,
	setShowAboutMe,
	introImgPosition,
	setIntroImgPosition,
}) => {
	const [widtha, setWidtha] = useState(window.innerWidth);
	const [heighta, setHeighta] = useState(window.innerHeight);

	const onContentBlur = CreateSanitizeCallback(setIntroText);
	useEffect(() => {
		function handelResize() {
			setWidtha(window.innerWidth);
			setHeighta(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [widtha, heighta]);

	const { width, height } = useSelector((state) => state.auth);

	return (
		<div className={style.container3}>
			{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
			<div className={style.myIntro}>My Intro</div>

			{!editable && (
				<ImageUpload
					className={style.introChangeIcon}
					setImage={setIntroImage}
					setImageMobile={setIntroImageMobile}
					image={introImage}
					imageMobile={introImageMobile}
					activeId={"introImgPosition"}
					initialPosition={introImgPosition}
					setInitialPosition={setIntroImgPosition}
				/>
			)}
			<div className={style.galleryImage}>
				<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
					{width < 950 && height > 600 ? (
						<img src={introImageMobile} alt="introImageMobile" />
					) : (
						<img src={introImage} alt="introImage" />
					)}
				</ImageRender>
			</div>

			<div className={style.galleryFrame}>
				{widtha < 950 && heighta > 600 ? (
					<img src={mobileFrame} loading="lazy" alt="" />
				) : (
					<img src={introFrame} loading="lazy" alt="" />
				)}
				<ContentEditableDiv className={style.galleryDesc} text={introText} onChange={onContentBlur} contentEditable={!editable} />
				<div className={style.buttons}>
					<button>
						<span onClick={() => setShowAboutMe(true)}>About me</span>
					</button>
					{/* <button>Hire Me</button> */}
				</div>
			</div>

			<div className={style.bottomDot}>
				<img src={dot} alt="" />
			</div>
		</div>
	);
};

export default Container3;
