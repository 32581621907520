import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template2.module.scss";
import { useSelector } from "react-redux";

function Container2({
	editable,
	aboutCompanyText,
	aboutCompanyImg1,
	aboutCompanyImg1Mobile,
	setAboutCompanyText,
	setAboutCompanyImg1,
	setAboutCompanyImg1Mobile,
	aboutCompanyImg1Position,
	setAboutCompanyImg1Position,
}) {
	const onContentBlur = CreateSanitizeCallback(setAboutCompanyText);
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.Container2}>
			<div className={styles.leftSection}>
				<div className={styles.companyDetails}>
					<h2> ABOUT COMPANY</h2>
					{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
					<ContentEditableDiv
						className={styles.aboutusText}
						text={aboutCompanyText}
						onChange={onContentBlur}
						contentEditable={!editable}
					/>
				</div>
				<button>We're hiring</button>
			</div>
			<div className={styles.rightSection}>
				<div className={styles.heroImage}>
					{!editable && (
						<ImageUpload
							className={styles.aboutImageChangeIcon}
							setImage={setAboutCompanyImg1}
							setImageMobile={setAboutCompanyImg1Mobile}
							image={aboutCompanyImg1}
							imageMobile={aboutCompanyImg1Mobile}
							activeId={"aboutCompanyImg1Position"}
							initialPosition={aboutCompanyImg1Position}
							setInitialPosition={setAboutCompanyImg1Position}
						/>
					)}
					<ImageRender initialPosition={aboutCompanyImg1Position} editable={editable} currentId={"aboutCompanyImg1Position"}>
						{width < 931 && height > 600 ? (
							<img src={aboutCompanyImg1Mobile} alt="aboutCompanyImg1Mobile" />
						) : (
							<img src={aboutCompanyImg1} alt="aboutCompanyImg1" />
						)}
					</ImageRender>
				</div>
			</div>
		</div>
	);
}

export default Container2;
