import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import RatingImg from "../../assets/images/rating.png";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../../components/TextArea";
import asset from "./Images/assets.png";
import styles from "./Template2.module.scss";

const Container1 = ({
	editable,
	name,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	postCount,
	followers,
	followings,
	setShowRating,
	ratingData,
	introText2,
	setIntroText2,
	coverImgPosition,
	setCoverImgPosition,
}) => {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={styles.Container1}>
			<div className={styles.Left}>
				<h3>I’M</h3>

				{/* <TextArea className={styles.h1} readOnly={editable} value={name} setValue={setName} /> */}
				<div className={styles.h1} style={{ fontSize: name.length > 17 ? (name.length > 25 ? "2.5rem" : "3rem") : "3.5rem" }}>
					{name.split(" ").map((data, index) => (
						<p key={index}>{data} </p>
					))}
				</div>

				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<TextArea className={styles.p} readOnly={editable} value={introText2} setValue={setIntroText2} />

				<div className={styles.ButtonContainer}>
					{userId === currentUserId ? (
						<button
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							Following <br /> List
						</button>
					) : (
						<FollowButton />
					)}

					{userId === currentUserId ? (
						<div
							className={styles.button}
							style={{ cursor: "pointer" }}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<h4>{followings}</h4>
							<h5>Following</h5>
						</div>
					) : (
						<div className={styles.button}>
							<h4>{followers}</h4>
							<h5>Followers</h5>
						</div>
					)}

					<MessageButton />
				</div>
			</div>

			<div className={styles.Right}>
				{!editable && (
					<ImageUpload
						className={styles.coverImage}
						setImage={setCoverImage}
						setImageMobile={setCoverImageMobile}
						image={coverImage}
						imageMobile={coverImageMobile}
						activeId={"coverImg"}
						initialPosition={coverImgPosition}
						setInitialPosition={setCoverImgPosition}
					/>
				)}
				<div className={styles.ImageContainer}>
					<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImg"}>
						{width < height ? (
							<img src={coverImageMobile} alt="UnionMobile" className={styles.img1} />
						) : (
							<img src={coverImage} alt="Union" className={styles.img1} />
						)}
					</ImageRender>
					<img src={asset} alt="asset" className={styles.img2} />
				</div>
			</div>

			<div className={styles.RatingImg} onClick={handleRating}>
				<img src={RatingImg} alt="RatingImg" />
				<span>{ratingData}</span>
			</div>
		</div>
	);
};

export default Container1;
