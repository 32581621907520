import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template10.module.scss";

const Container3 = ({
	editable,
	introImage,
	introImageMobile,
	setIntroImage,
	setIntroImageMobile,
	introImgPosition,
	setIntroImgPosition,
}) => {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.contain3}>
			{!editable && (
				<ImageUpload
					className={styles.IntroImageBtn}
					setImage={setIntroImage}
					setImageMobile={setIntroImageMobile}
					image={introImage}
					imageMobile={introImageMobile}
					activeId={"introImgPosition"}
					initialPosition={introImgPosition}
					setInitialPosition={setIntroImgPosition}
				/>
			)}
			<div>
				<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"introImgPosition"}>
					{width < 950 && height > 400 ? (
						<img src={introImageMobile} className={styles.ImageCon3} alt="introImageMobile" />
					) : (
						<img src={introImage} className={styles.ImageCon3} alt="introImage" />
					)}
				</ImageRender>
			</div>
			<div>
				<img className={styles.BackgroundCon3} alt="" />
			</div>
		</div>
	);
};
export default Container3;
