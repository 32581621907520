import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ImageRenderControls from "./components/EditingTool/ImageRenderControls";

const Tasting = () => {
	// const Controls = ({ zoomIn, zoomOut, resetTransform, setTransform }) => {

	// 	return (
	// 		<div
	// 			style={{
	// 				position: "absolute",
	// 				bottom: "1rem",
	// 				right: "0",
	// 				display: "flex",
	// 				gap: "1rem",
	// 			}}>
	// 			<input
	// 				type="range"
	// 				max={1000}
	// 				min={-1000}
	// 				// value={x}
	// 				onChange={(e) => onChange(e.target.value)}
	// 			/>
	// 			<input
	// 				type="range"
	// 				max={100}
	// 				min={-100}
	// 				value={state.y}
	// 				onChange={(e) => setState({ ...state, y: e.target.value })}
	// 			/>
	// 			<input
	// 				type="range"
	// 				max={10}
	// 				min={1}
	// 				value={state.s}
	// 				onChange={(e) => setState({ ...state, s: e.target.value })}
	// 			/>
	// 			<button style={{ width: "5rem", height: "2rem" }} onClick={() => zoomIn()}>
	// 				+
	// 			</button>
	// 			<button style={{ width: "5rem", height: "2rem" }} onClick={() => zoomOut()}>
	// 				-
	// 			</button>
	// 			<button style={{ width: "5rem", height: "2rem" }} onClick={() => resetTransform()}>
	// 				x
	// 			</button>
	// 		</div>
	// 	);
	// };

	return (
		<div style={{ border: "3px solid red" }}>
			<TransformWrapper
				onWheelStop={({ state }) => console.log("While=> ", state)}
				onPinchingStop={({ state }) => console.log("Pinch=> ", state)}
				onPanningStop={({ state }) => console.log("Panning=> ", state)}>
				{(utils) => (
					<React.Fragment>
						<ImageRenderControls {...utils} />
						<TransformComponent>
							<img src="https://picsum.photos/2000/800" alt="test" id="imgExample" />
						</TransformComponent>
					</React.Fragment>
				)}
			</TransformWrapper>
		</div>
	);
};

export default Tasting;
