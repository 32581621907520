import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import setting from "../../assets/images/Setting.svg";
import AboutMe from "../../components/AboutMe/AboutMe";
import HomeButton from "../../components/HomeButton/HomeButton";
import Lodging from "../../components/Lodging/Lodging";
import NavBar from "../../components/NavBar/NavBar";
import Rating from "../../components/Rating/Rating";
import Container1 from "./Container1";
import Container2 from "./Container2";
import Container2m from "./Container2m";
import Container3 from "./Container3";
import Container4 from "./Container4";
import Container4m from "./Container4m";
import styles from "./Template10.module.scss";
import "./Template10.scss";

const Template10 = () => {
	const { user, templateData, isLoading, currentUserId, userId } = useSelector((state) => state.auth);
	const [width, setWidth] = useState(window.innerWidth);
	const { state } = useLocation();
	document.documentElement.style.setProperty("--base-font-size", "100%");

	useEffect(() => {
		function handelResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width]);

	if (width < 1728) document.documentElement.style.setProperty("--base-font-size", "90%");
	if (width < 1500) document.documentElement.style.setProperty("--base-font-size", "80%");
	if (width < 1350) document.documentElement.style.setProperty("--base-font-size", "70%");
	if (width < 1250) document.documentElement.style.setProperty("--base-font-size", "70%");
	if (width < 1150) document.documentElement.style.setProperty("--base-font-size", "65%");
	if (width < 1024) document.documentElement.style.setProperty("--base-font-size", "60%");
	if (width < 900) document.documentElement.style.setProperty("--base-font-size", "55%");
	if (width < 800) document.documentElement.style.setProperty("--base-font-size", "50%");

	const [editable, setEditable] = useState(true);
	const [showRating, setShowRating] = useState(false);
	const [ratingData, setRatingData] = useState("");
	const [showAboutMe, setShowAboutMe] = useState(false);
	const [showNav, setShowNav] = useState(false);
	const [name, setName] = useState("");
	const [introImg1, setIntroImg1] = useState("");
	const [introImg1Mobile, setIntroImg1Mobile] = useState("");
	const [introImg2, setIntroImg2] = useState("");
	const [introImg2Mobile, setIntroImg2Mobile] = useState("");
	const [coverImage, setCoverImage] = useState("");
	const [coverImageMobile, setCoverImageMobile] = useState("");
	const [postCount, setPostCount] = useState("");
	const [followers, setFollowers] = useState("");
	const [followings, setFollowings] = useState("");
	const [introText, setIntroText] = useState("");
	const [introImage, setIntroImage] = useState("");
	const [introImageMobile, setIntroImageMobile] = useState("");
	const [shortMessage, setShortMessage] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [liveIn, setLiveIn] = useState("");
	const [schooling, setSchooling] = useState("");
	const [profession, setProfession] = useState("");
	const [coverImgPosition, setCoverImgPosition] = useState("");
	const [introImg1Position, setIntroImg1Position] = useState("");
	const [introImg2Position, setIntroImg2Position] = useState("");
	const [introImgPosition, setIntroImgPosition] = useState("");

	useEffect(() => {
		if (state.viewMode) setEditable(false);
		if (templateData === null) return;
		setRatingData(user?.Rating === undefined ? "0" : user?.Rating);
		setName(user?.username === "" || user?.username === undefined ? templateData?.name : user?.username);
		setIntroImg1(templateData?.introImg1);
		setIntroImg1Mobile(templateData?.introImg1Mobile);
		setIntroImg2(templateData?.introImg2);
		setIntroImg2Mobile(templateData?.introImg2Mobile);
		setCoverImage(templateData?.coverImage);
		setCoverImageMobile(templateData?.coverImageMobile);
		setPostCount(user?.postCount || "0");
		setFollowers(user?.followersData?.length || "0");
		setFollowings(user?.followingData?.length || "0");
		// setIntroText(templateData?.introText);
		setIntroText(user?.bio === "" ? templateData?.introText : user?.bio);
		setIntroImage(templateData?.introImage);
		setIntroImageMobile(templateData?.introImageMobile);
		setShortMessage(templateData?.shortMessage);
		setEmail(user?.email === "" || user?.email === undefined ? "James.test@mail.co" : user?.email);
		setPhone(user?.mobile_number === "" || user?.mobile_number === undefined ? "+91 323 323 3245" : user?.mobile_number);
		setLiveIn(templateData?.aboutMe?.liveIn);
		setSchooling(templateData?.aboutMe?.schooling);
		setProfession(templateData?.aboutMe?.profession);
		setCoverImgPosition(templateData?.coverImgPosition);
		setIntroImg1Position(templateData?.introImg1Position);
		setIntroImg2Position(templateData?.introImg2Position);
		setIntroImgPosition(templateData?.introImgPosition);
	}, [user, templateData]);

	const templateUpdatedData = {
		name,
		coverImage,
		coverImageMobile,
		introImg1,
		introImg1Mobile,
		introImg2,
		introImg2Mobile,
		introImage,
		introImageMobile,
		introText,
		shortMessage,
		coverImgPosition,
		introImg1Position,
		introImg2Position,
		introImgPosition,
		aboutMe: {
			email,
			phone,
			liveIn,
			schooling,
			profession,
		},
		hireMe: {
			hireable: false,
		},
	};

	const breakpoint = 800;

	return (
		<>
			{isLoading && <Lodging />}
			<NavBar
				editable={editable}
				setEditable={setEditable}
				showNav={showNav}
				setShowNav={setShowNav}
				templateUpdatedData={templateUpdatedData}
			/>
			{showRating && <Rating setShowRating={setShowRating} setRatingData={setRatingData} />}
			{showAboutMe && (
				<AboutMe
					editable={editable}
					setShowAboutMe={setShowAboutMe}
					email={email}
					setEmail={setEmail}
					phone={phone}
					setPhone={setPhone}
					liveIn={liveIn}
					setLiveIn={setLiveIn}
					schooling={schooling}
					setSchooling={setSchooling}
					profession={profession}
					setProfession={setProfession}
				/>
			)}

			<div className={styles.Template10Wrapper} id="Temp10" style={{ background: "" }}>
				{!showNav && currentUserId === userId && user && !state.viewMode && (
					<img src={setting} alt="" className={styles.Setting} onClick={() => setShowNav(true)} />
				)}

				<div className={styles.Template10}>
					<HomeButton {...{ templateUpdatedData }} />
					<Container1
						editable={editable}
						name={name}
						setName={setName}
						coverImage={coverImage}
						coverImageMobile={coverImageMobile}
						setCoverImage={setCoverImage}
						setCoverImageMobile={setCoverImageMobile}
						postCount={postCount}
						followers={followers}
						followings={followings}
						setShowRating={setShowRating}
						ratingData={ratingData}
						coverImgPosition={coverImgPosition}
						setCoverImgPosition={setCoverImgPosition}
					/>

					{window.innerWidth <= breakpoint ? (
						<Container2m
							editable={editable}
							intro={introText}
							setIntro={setIntroText}
							introImg1={introImg1}
							introImg1Mobile={introImg1Mobile}
							setIntroImg1={setIntroImg1}
							setIntroImg1Mobile={setIntroImg1Mobile}
							introImg2={introImg2}
							introImg2Mobile={introImg2Mobile}
							setIntroImg2={setIntroImg2}
							setIntroImg2Mobile={setIntroImg2Mobile}
							setShowAboutMe={setShowAboutMe}
							introImg1Position={introImg1Position}
							setIntroImg1Position={setIntroImg1Position}
							introImg2Position={introImg2Position}
							setIntroImg2Position={setIntroImg2Position}
						/>
					) : (
						<Container2
							editable={editable}
							intro={introText}
							setIntro={setIntroText}
							introImg1={introImg1}
							introImg1Mobile={introImg1Mobile}
							setIntroImg1={setIntroImg1}
							setIntroImg1Mobile={setIntroImg1Mobile}
							introImg2={introImg2}
							introImg2Mobile={introImg2Mobile}
							setIntroImg2={setIntroImg2}
							setIntroImg2Mobile={setIntroImg2Mobile}
							setShowAboutMe={setShowAboutMe}
							introImg1Position={introImg1Position}
							setIntroImg1Position={setIntroImg1Position}
							introImg2Position={introImg2Position}
							setIntroImg2Position={setIntroImg2Position}
						/>
					)}

					<Container3
						editable={editable}
						introImage={introImage}
						introImageMobile={introImageMobile}
						setIntroImage={setIntroImage}
						setIntroImageMobile={setIntroImageMobile}
						introImgPosition={introImgPosition}
						setIntroImgPosition={setIntroImgPosition}
					/>

					<div>
						{window.innerWidth <= breakpoint ? (
							<Container4m editable={editable} shortStory={shortMessage} setShortStory={setShortMessage} />
						) : (
							<Container4 editable={editable} shortStory={shortMessage} setShortStory={setShortMessage} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Template10;
