import React from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template4.module.scss";

function Container3({
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	introImg3,
	introImg3Mobile,
	setIntroImg3,
	setIntroImg3Mobile,
	editable,
	introImg1Position,
	introImg2Position,
	introImg3Position,
	setIntroImg1Position,
	setIntroImg2Position,
	setIntroImg3Position,
}) {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={style.container3}>
			<div className={style.image_container}>
				{!editable && (
					<ImageUpload
						className={style.int1ChangeIcon}
						setImage={setIntroImg1}
						setImageMobile={setIntroImg1Mobile}
						image={introImg1}
						imageMobile={introImg1Mobile}
						activeId={"introImg1Position"}
						initialPosition={introImg1Position}
						setInitialPosition={setIntroImg1Position}
					/>
				)}
				<div className={style.coverImage1}>
					<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
						{width < 950 && height > 600 ? (
							<img src={introImg1Mobile} alt={"introImg1Mobile"} />
						) : (
							<img src={introImg1} alt={"introImg1"} />
						)}
					</ImageRender>
				</div>

				{!editable && (
					<ImageUpload
						className={style.int2ChangeIcon}
						setImage={setIntroImg2}
						setImageMobile={setIntroImg2Mobile}
						image={introImg2}
						imageMobile={introImg2Mobile}
						activeId={"introImg2Position"}
						initialPosition={introImg2Position}
						setInitialPosition={setIntroImg2Position}
					/>
				)}
				<div className={style.coverImage2}>
					<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
						{width < 950 && height > 600 ? (
							<img src={introImg2Mobile} alt={"introImg2Mobile"} />
						) : (
							<img src={introImg2} alt={"introImg2"} />
						)}
					</ImageRender>
				</div>
				{!editable && (
					<ImageUpload
						className={style.int3ChangeIcon}
						setImage={setIntroImg3}
						setImageMobile={setIntroImg3Mobile}
						image={introImg3}
						imageMobile={introImg3Mobile}
						activeId={"introImg3Position"}
						initialPosition={introImg3Position}
						setInitialPosition={setIntroImg3Position}
					/>
				)}
				<div className={style.coverImage3}>
					<ImageRender initialPosition={introImg3Position} editable={editable} currentId={"introImg3Position"}>
						{width < 950 && height > 600 ? (
							<img src={introImg3Mobile} alt={"introImg3Mobile"} />
						) : (
							<img src={introImg3} alt={"introImg3"} />
						)}
					</ImageRender>
				</div>
			</div>
		</div>
	);
}

export default Container3;
