import React from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template10.module.scss";
import ratingIcon from "./images/ratingIcon.png";

const Container1 = ({
	editable,
	name,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	setShowRating,
	ratingData,
	postCount,
	followers,
	followings,
	coverImgPosition,
	setCoverImgPosition,
}) => {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<>
			<div className={styles.Container1}>
				<div className={styles.ImageContainer}>
					{!editable && (
						<ImageUpload
							className={styles.CoverUploadBtn}
							setImage={setCoverImage}
							setImageMobile={setCoverImageMobile}
							image={coverImage}
							imageMobile={coverImageMobile}
							activeId={"coverImgPosition"}
							initialPosition={coverImgPosition}
							setInitialPosition={setCoverImgPosition}
						/>
					)}
					<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
						{width < 950 && height > 400 ? (
							<img src={coverImageMobile} alt="coverImageMobile" />
						) : (
							<img src={coverImage} alt="coverImage" />
						)}
					</ImageRender>
					<div className={styles.BlurOverlay}></div>
					{/* <div className={styles.DarkOverlay1}></div> */}
					<div className={styles.DarkOverlay2}></div>
				</div>

				<div className={styles.BorderBox}>
					<div className={styles.RatingCon} onClick={handleRating}>
						<img src={ratingIcon} alt="" />
						<span>{ratingData}</span>
					</div>

					<div className={styles.NameBox}>
						<div className={styles.HR1flex}>
							<div className={styles.HR1}></div>
						</div>
						<div className={styles.nameCon1}>
							<div className={styles.TextWidth}>I'm</div>
							<div className={styles.TextWidth2}>{name}</div>
						</div>
						<div className={styles.HR2flex}>
							<div className={styles.HR2}></div>
						</div>
					</div>

					<div className={styles.ContentBox}>
						<div className={styles.left}>
							<div>
								{userId === currentUserId ? (
									<button
										onClick={() => {
											window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
										}}>
										<p>{followings}</p>
										Following
									</button>
								) : (
									<FollowButton />
								)}
							</div>
							<div>
								<MessageButton />
							</div>
						</div>

						<div className={styles.right}>
							{userId === currentUserId ? (
								<div
									style={{ cursor: "pointer" }}
									onClick={() => {
										window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
									}}>
									<h1>{followers}</h1>
									<p>FOLLOWERS</p>
								</div>
							) : (
								<div>
									<h1>{followers}</h1>
									<p>FOLLOWERS</p>
								</div>
							)}

							<div>
								<h1>{postCount}</h1>
								<p>POSTS</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Container1;
