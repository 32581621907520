import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template4.module.scss";
import coverFrame from "./images/coverFrame.png";
import coverFrame1 from "./images/mobileFrame.png";

function Container1({
	editable,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	name,
	setName,
	followings,
	followers,
	postCount,
	ratingData,
	setShowRating,
	coverImgPosition,
	setCoverImgPosition,
}) {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);
	const [widtha, setWidtha] = useState(window.innerWidth);
	const [heighta, setHeighta] = useState(window.innerHeight);
	useEffect(() => {
		function handelResize() {
			setWidtha(window.innerWidth);
			setHeighta(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [widtha, heighta]);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={style.container1}>
			<div className={style.coverImage}>
				<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImg"}>
					{width < 950 && height > 600 ? (
						<img src={coverImageMobile} alt="coverImageMobile" />
					) : (
						<img src={coverImage} alt="coverImage" />
					)}
				</ImageRender>
				{!editable && (
					<ImageUpload
						className={style.coverChangeIcon}
						setImage={setCoverImage}
						setImageMobile={setCoverImageMobile}
						image={coverImage}
						imageMobile={coverImageMobile}
						activeId={"coverImg"}
						initialPosition={coverImgPosition}
						setInitialPosition={setCoverImgPosition}
					/>
				)}
			</div>
			<div className={style.coverFrame}>
				{widtha < 950 && heighta > 600 ? (
					<img src={coverFrame1} loading="lazy" alt="" />
				) : (
					<img src={coverFrame} loading="lazy" alt="" />
				)}
				<div className={style.buttons}>
					{userId === currentUserId ? (
						<button
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<p>{followings}</p>
							Following
						</button>
					) : (
						<FollowButton />
					)}
					<MessageButton />
				</div>

				{userId === currentUserId ? (
					<div
						className={style.followers}
						style={{ cursor: "pointer" }}
						onClick={() => {
							window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
						}}>
						<div className={style.followerCount}>{followers}</div>
						<div className={style.textFollower}>Followers</div>
					</div>
				) : (
					<div className={style.followers}>
						<div className={style.followerCount}>{followers}</div>
						<div className={style.textFollower}>Followers</div>
					</div>
				)}

				<div className={style.nameIcon}>
					<div className={style.name}>
						<div className={style.coverName} style={{ fontSize: name.length > 10 ? (name.length >= 17 ? "5vh" : "6vh") : "7vh" }}>
							I'm {name}
						</div>
					</div>
					<div className={style.icon}>
						<div className={style.iconContainer} onClick={handleRating}>
							{ratingData}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Container1;
