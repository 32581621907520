import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../Template1/TextArea";
import styles from "./Template14.module.scss";

function Container2({
	editable,
	introText,
	setIntrotext,
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg2,
	introImg2Mobile,
	setIntroImg2,
	setIntroImg2Mobile,
	introImg3,
	introImg3Mobile,
	setIntroImg3,
	setIntroImg3Mobile,
	introImg4,
	introImg4Mobile,
	setIntroImg4,
	setIntroImg4Mobile,
	introImg5,
	introImg5Mobile,
	setIntroImg5,
	setIntroImg5Mobile,
	introImg6,
	introImg6Mobile,
	setIntroImg6,
	setIntroImg6Mobile,
	introImg7,
	introImg7Mobile,
	setIntroImg7,
	setIntroImg7Mobile,
	setShowAboutMe,
	introImg1Position,
	setIntroImg1Position,
	introImg2Position,
	setIntroImg2Position,
	introImg3Position,
	setIntroImg3Position,
	introImg4Position,
	setIntroImg4Position,
	introImg5Position,
	setIntroImg5Position,
	introImg6Position,
	setIntroImg6Position,
	introImg7Position,
	setIntroImg7Position,
}) {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.Container2}>
			<div className={styles.myIntro}>My Intro</div>
			<div className={styles.content}>
				<div className={styles.leftSection}>
					<div className={styles.introImg1}>
						<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
							{width < height && width < 931 ? (
								<img src={introImg1Mobile} alt="introImg1Mobile" />
							) : (
								<img src={introImg1} alt="introImg1" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.intro1ChangeIcon}
								setImage={setIntroImg1}
								setImageMobile={setIntroImg1Mobile}
								image={introImg1}
								imageMobile={introImg1Mobile}
								activeId={"introImg1Position"}
								initialPosition={introImg1Position}
								setInitialPosition={setIntroImg1Position}
							/>
						)}
						<div className={styles.introImg2}>
							<ImageRender initialPosition={introImg2Position} editable={editable} currentId={"introImg2Position"}>
								{width < height && width < 931 ? (
									<img src={introImg2Mobile} alt="introImg2Mobile" />
								) : (
									<img src={introImg2} alt="introImg2" />
								)}
							</ImageRender>
							{!editable && (
								<ImageUpload
									className={styles.intro2ChangeIcon}
									setImage={setIntroImg2}
									setImageMobile={setIntroImg2Mobile}
									image={introImg2}
									imageMobile={introImg2Mobile}
									activeId={"introImg2Position"}
									initialPosition={introImg2Position}
									setInitialPosition={setIntroImg2Position}
								/>
							)}
						</div>
					</div>
				</div>
				<div className={styles.rightSection}>

					<div className={styles.TextWrapper}>
						{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
						<TextArea className={styles.desc} readOnly={editable} value={introText} setValue={setIntrotext} />
					</div>
					<div className={styles.buttons}>
						<button onClick={() => setShowAboutMe(true)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
								<g clip-path="url(#clip0_295_7734)">
									<path
										d="M19.2824 17.5566L12.1328 10.4071L14.1751 8.36475L23.367 17.5566L14.1751 26.7485L12.1328 24.7062L19.2824 17.5566Z"
										fill="white"
									/>
								</g>
								<defs>
									<clipPath id="clip0_295_7734">
										<rect width="34.6646" height="34.6646" fill="white" transform="translate(0.257812 0.223633)" />
									</clipPath>
								</defs>
							</svg>
							About Me
						</button>
						{/* <button>
							<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
								<g clip-path="url(#clip0_295_7734)">
									<path
										d="M19.2824 17.5566L12.1328 10.4071L14.1751 8.36475L23.367 17.5566L14.1751 26.7485L12.1328 24.7062L19.2824 17.5566Z"
										fill="white"
									/>
								</g>
								<defs>
									<clipPath id="clip0_295_7734">
										<rect width="34.6646" height="34.6646" fill="white" transform="translate(0.257812 0.223633)" />
									</clipPath>
								</defs>
							</svg>
							Hire Me
						</button> */}
					</div>
				</div>
			</div>

			<div className={styles.introGallery}>
				<div className={styles.left}>
					<div className={styles.introImg3}>
						<ImageRender initialPosition={introImg3Position} editable={editable} currentId={"introImg3Position"}>
							{width < height && width < 931 ? (
								<img src={introImg3Mobile} alt="introImg3Mobile" />
							) : (
								<img src={introImg3} alt="introImg3" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg3}
								setImageMobile={setIntroImg3Mobile}
								image={introImg3}
								imageMobile={introImg3Mobile}
								activeId={"introImg3Position"}
								initialPosition={introImg3Position}
								setInitialPosition={setIntroImg3Position}
							/>
						)}
					</div>
					<div className={styles.introImg4}>
						<ImageRender initialPosition={introImg4Position} editable={editable} currentId={"introImg4Position"}>
							{width < height && width < 931 ? (
								<img src={introImg4Mobile} alt="introImg4Mobile" />
							) : (
								<img src={introImg4} alt="introImg4" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg4}
								setImageMobile={setIntroImg4Mobile}
								image={introImg4}
								imageMobile={introImg4Mobile}
								activeId={"introImg4Position"}
								initialPosition={introImg4Position}
								setInitialPosition={setIntroImg4Position}
							/>
						)}
					</div>
				</div>
				<div className={styles.mid}>
					<ImageRender initialPosition={introImg5Position} editable={editable} currentId={"introImg5Position"}>
						{width < height && width < 931 ? (
							<img src={introImg5Mobile} alt="introImg5Mobile" />
						) : (
							<img src={introImg5} alt="introImg5" />
						)}
					</ImageRender>
					{!editable && (
						<ImageUpload
							className={styles.introChangeIcon}
							setImage={setIntroImg5}
							setImageMobile={setIntroImg5Mobile}
							image={introImg5}
							imageMobile={introImg5Mobile}
							activeId={"introImg5Position"}
							initialPosition={introImg5Position}
							setInitialPosition={setIntroImg5Position}
						/>
					)}
				</div>
				<div className={styles.right}>
					<div className={styles.introImg6}>
						<ImageRender initialPosition={introImg6Position} editable={editable} currentId={"introImg6Position"}>
							{width < height && width < 931 ? (
								<img src={introImg6Mobile} alt="introImg6Mobile" />
							) : (
								<img src={introImg6} alt="introImg6" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg6}
								setImageMobile={setIntroImg6Mobile}
								image={introImg6}
								imageMobile={introImg6Mobile}
								activeId={"introImg6Position"}
								initialPosition={introImg6Position}
								setInitialPosition={setIntroImg6Position}
							/>
						)}
					</div>
					<div className={styles.introImg7}>
						<ImageRender initialPosition={introImg7Position} editable={editable} currentId={"introImg7Position"}>
							{width < height && width < 931 ? (
								<img src={introImg7Mobile} alt="introImg7Mobile" />
							) : (
								<img src={introImg7} alt="introImg7" />
							)}
						</ImageRender>
						{!editable && (
							<ImageUpload
								className={styles.introChangeIcon}
								setImage={setIntroImg7}
								setImageMobile={setIntroImg7Mobile}
								image={introImg7}
								imageMobile={introImg7Mobile}
								activeId={"introImg7Position"}
								initialPosition={introImg7Position}
								setInitialPosition={setIntroImg7Position}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Container2;
