import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../../components/TextArea";
import styles from "./Template15.module.scss";
import background from "./assets/backGround.png";

function Container2({
	editable,
	introText,
	setIntroText,
	introImg1,
	introImg1Mobile,
	setIntroImg1,
	setIntroImg1Mobile,
	introImg1Position,
	setIntroImg1Position,
	setShowAboutme,
}) {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.Container2}>
			{/* <div className={styles.backgroundWrapper}></div> */}
			<div className={styles.background}>
				<img src={background} alt="" />
			</div>
			<div className={styles.myIntro}>My Intro</div>
			<div className={styles.introText}>
				<div className={styles.backgroundWrapper}>
					<div className={styles.background1}></div>
					{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
					<TextArea className={styles.text} readOnly={editable} value={introText} setValue={setIntroText} />
				</div>
				<div className={styles.introImage}>
					<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"introImg1Position"}>
						{width < height && width < 931 ? (
							<img src={introImg1Mobile} alt="introImg1Mobile" />
						) : (
							<img src={introImg1} alt="introImg1" />
						)}
					</ImageRender>
					{!editable && (
						<ImageUpload
							className={styles.introChangeIcon}
							setImage={setIntroImg1}
							setImageMobile={setIntroImg1Mobile}
							image={introImg1}
							imageMobile={introImg1Mobile}
							activeId={"introImg1Position"}
							initialPosition={introImg1Position}
							setInitialPosition={setIntroImg1Position}
						/>
					)}
				</div>
			</div>
			<div className={styles.buttons}>
				<button onClick={() => setShowAboutme(true)}>About Me</button>
				<button>Hire Me</button>
			</div>
		</div>
	);
}

export default Container2;
