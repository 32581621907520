import React from "react";
import { useSelector } from "react-redux";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template.module.scss";

const Container1 = ({
	editable,
	name,
	coverPNGImage,
	setCoverPNGImage,
	coverPNGImageMobile,
	setCoverPNGImageMobile,
	coverImage,
	setCoverImage,
	coverImageMobile,
	setCoverImageMobile,
	postCount,
	followers,
	followings,
	setShowRating,
	ratingData,
	coverImgPosition,
	coverPNGImgPosition,
	setCoverImgPosition,
	setCoverPNGImgPosition,
}) => {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={styles.Container1}>
			<div className={styles.Top}>
				{!editable && (
					<ImageUpload
						className={styles.coverImageIcon}
						image={coverImage}
						setImage={setCoverImage}
						setImageMobile={setCoverImageMobile}
						imageMobile={coverImageMobile}
						activeId={"coverImgPosition"}
						initialPosition={coverImgPosition}
						setInitialPosition={setCoverImgPosition}
					/>
				)}
				<div className={styles.backgroundImage}>
					<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
						{width < 900 && height > 400 ? (
							<img src={coverImageMobile} alt="coverMobileImage" loading="lazy" />
						) : (
							<img src={coverImage} alt="coverImage" loading="lazy" />
						)}
					</ImageRender>
				</div>

				<div className={styles.MainWrapper}>
					<div className={styles.MainTop}>
						<div className={styles.ImageWrapper}>
							{!editable && (
								<ImageUpload
									className={styles.coverPNGImageIcon}
									condition="png"
									image={coverPNGImage}
									setImage={setCoverPNGImage}
									imageMobile={coverPNGImageMobile}
									setImageMobile={setCoverPNGImageMobile}
									activeId={"coverPNGImgPosition"}
									initialPosition={coverPNGImgPosition}
									setInitialPosition={setCoverPNGImgPosition}
								/>
							)}
							<ImageRender initialPosition={coverPNGImgPosition} editable={editable} currentId={"coverPNGImgPosition"}>
								{width < 900 && height > 400 ? (
									<img src={coverPNGImageMobile} alt="coverPNGImageMobile" loading="lazy" />
								) : (
									<img src={coverPNGImage} alt="coverPNGImage" loading="lazy" />
								)}
							</ImageRender>
						</div>

						<div className={styles.TextWrapper}>
							<p
								style={{
									fontSize: name?.length > 6 ? (name?.length > 7 ? "1.5rem" : "2.5rem") : "4rem",
								}}>
								{name?.split("").map((data, index) => (
									<span key={index}>{data}</span>
								))}
							</p>
						</div>
					</div>

					<div className={styles.MainMiddle}>
						<div className={styles.MainTopWrapper}>
							<p>I’m</p>
							<h1
								style={{
									backgroundImage: `url(${coverImage})`,
									backgroundSize: "cover",
									padding: "0rem 1rem",
								}}>
								{name}
							</h1>

							<div className={styles.FollowersWrapper1}>{followers}</div>
						</div>

						{userId === currentUserId ? (
							<div
								className={styles.FollowersWrapper2}
								style={{ cursor: "pointer" }}
								onClick={() => {
									window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
								}}>
								{followers}
								<p className={styles.FollowersText}>Followers</p>
							</div>
						) : (
							<div className={styles.FollowersWrapper2}>
								{followers}
								<p className={styles.FollowersText}>Followers</p>
							</div>
						)}
					</div>

					<div className={styles.MainBottom}>
						<MessageButton />
						{userId === currentUserId ? (
							<button
								onClick={() => {
									window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
								}}>
								{followings} <br /> Followings
							</button>
						) : (
							<FollowButton />
						)}
					</div>
				</div>
			</div>

			<div className={styles.RatingContainer} onClick={handleRating}>
				<p>{ratingData}</p>
			</div>
		</div>
	);
};

export default Container1;
