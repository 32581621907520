import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import Tasting from "./Tasting";
import BTemplate3 from "./business/BTemplate3/Template3";
import BTemplate4 from "./business/BTemplate4/Template4";
import BTemplate5 from "./business/BTemplate5/Btemplate5";
import BTemplate1 from "./business/Btemplate1/Btemplate1";
import BTemplate10 from "./business/Btemplate10/Btemplate10";
import BTemplate2 from "./business/Btemplate2/Btemplate2";
import BTemplate6 from "./business/Btemplate6/Btemplate6";
import BTemplate7 from "./business/Btemplate7/Btemplate7";
import BTemplate8 from "./business/Btemplate8/Btemplate8";
import BTemplate9 from "./business/Btemplate9/Btemplate9";
import Editing from "./components/EditingTool/Editing";
import PositionRenderControls from "./components/EditingTool/PositionRenderControls";
import Gallery from "./components/Gallery/Gallery";
import axios from "./components/Hooks/axios";
import { clearCacheData } from "./components/Hooks/clearCacheData";
import RoutesFunction from "./components/Routes";
import WebPage from "./components/WebPage";
import Template1 from "./pages/Template1/Template1";
import Template10 from "./pages/Template10/Template10";
import Template11 from "./pages/Template11/Template11";
import Template12 from "./pages/Template12/Template12";
import Template13 from "./pages/Template13/Template13";
import Template14 from "./pages/Template14/Template14";
import Template15 from "./pages/Template15/Template15";
import Template2 from "./pages/Template2/Template2";
import Template3 from "./pages/Template3/Template3";
import Template4 from "./pages/Template4/Template4";
import Template5 from "./pages/Template5/Template5";
import Template6 from "./pages/Template6/Template6";
import Template7 from "./pages/Template7/Template7";
import Template8 from "./pages/Template8/Template8";
import Template9 from "./pages/Template9/Template9";
import {
	fetchCurrentData,
	fetchDefaultTemplate,
	fetchPrevUserTemplate,
	fetchTemplateData,
	fetchUserData,
	saveTokenData,
	setIsMobile,
	updateTemplateData,
} from "./redux/slice/authSlice";

export const socket = io(`${process.env.REACT_APP_BASE_API_URL}/socket_connection`);

const RouteWrapper = () => {
	const dispatch = useDispatch();

	const {
		state: { personaUserId, currentUserId, token, templateId, viewMode },
	} = useLocation();

	useEffect(() => {
		console.log("Index=> ", personaUserId, currentUserId, token, templateId);

		if (token === undefined) return;
		if (token) dispatch(saveTokenData(token));

		if (templateId) dispatch(fetchDefaultTemplate(templateId));

		if (personaUserId === undefined) return;
		if (personaUserId) dispatch(fetchUserData(personaUserId));
		if (viewMode) {
			if (personaUserId) dispatch(fetchPrevUserTemplate(personaUserId));
		} else {
			Cookies.remove("EP_viewMode");
			if (personaUserId) dispatch(fetchTemplateData(personaUserId));
		}

		if (currentUserId === undefined) return;
		if (currentUserId) dispatch(fetchCurrentData(currentUserId));
	}, [personaUserId, currentUserId, token, templateId]);

	useEffect(() => {
		const listener = (event) => {
			if (event.key === "temp") {
				const newData = JSON.parse(event.newValue);
				// Object.assign(templateUpdatedData, newData, {
				// 	aboutMe: Object.assign({}, templateUpdatedData.aboutMe, newData.aboutMe),
				// 	hireMe: Object.assign({}, templateUpdatedData.hireMe, newData.hireMe),
				// });
				dispatch(updateTemplateData(newData));
			}
		};

		window.addEventListener("storage", listener);

		return () => {
			window.removeEventListener("storage", listener);
		};
	}, []);

	return <Outlet />;
};

const App = () => {
	document.documentElement.style.setProperty("--base-font-size", "100%");
	const dispatch = useDispatch();
	const { isEditingOpen, positionEdit } = useSelector((s) => s.temp);
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		dispatch(setIsMobile({ width: window.innerWidth, height: window.innerHeight }));
		function handelResize() {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
			dispatch(setIsMobile({ width: window.innerWidth, height: window.innerHeight }));
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width, height]);

	useEffect(() => {
		const userIdCookie = Cookies.get("EP_persona_userId");
		const currentUserIdCookie = Cookies.get("EP_current_userId");
		const tokenCookie = Cookies.get("EP_token");
		const viewModeCookie = Cookies.get("EP_viewMode");

		if (tokenCookie === undefined) return;
		if (tokenCookie) dispatch(saveTokenData(tokenCookie));

		if (userIdCookie === undefined) return;
		if (userIdCookie) dispatch(fetchUserData(JSON.parse(userIdCookie)));
		if (viewModeCookie) {
			if (userIdCookie) dispatch(fetchPrevUserTemplate(JSON.parse(userIdCookie)));
		} else {
			if (userIdCookie) dispatch(fetchTemplateData(JSON.parse(userIdCookie)));
		}

		if (currentUserIdCookie === undefined) return;
		if (currentUserIdCookie) dispatch(fetchCurrentData(JSON.parse(currentUserIdCookie)));
	}, []);

	useEffect(() => {
		const handelUnload = () => (axios.defaults.headers.authorization = "");
		window.addEventListener("beforeunload", handelUnload);
		return () => window.removeEventListener("beforeunload", handelUnload);
	}, []);

	clearCacheData();

	return (
		<>
			{isEditingOpen && <Editing />}
			{positionEdit.status && <PositionRenderControls />}

			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
				theme="colored"
			/>
			<Routes>
				<Route path="/" element={<RoutesFunction />} />
				<Route path="/:templateId/:token" element={<RoutesFunction />} />
				<Route path="/:personaUserId/:currentUserId/:token" element={<RoutesFunction />} />
				<Route path="/:personaUserId/:templateId/:token/:viewMode" element={<RoutesFunction />} />

				<Route path="/Gallery" element={<Gallery />} />
				<Route path="/Temps" element={<WebPage />} />

				<Route path="/BusinessTemplate2" element={<BTemplate2 />} />
				<Route path="/BusinessTemplate3" element={<BTemplate3 />} />
				<Route path="/BusinessTemplate4" element={<BTemplate4 />} />
				<Route path="/BusinessTemplate5" element={<BTemplate5 />} />
				<Route path="/BusinessTemplate6" element={<BTemplate6 />} />
				<Route path="/BusinessTemplate7" element={<BTemplate7 />} />
				<Route path="/BusinessTemplate8" element={<BTemplate8 />} />
				<Route path="/BusinessTemplate9" element={<BTemplate9 />} />
				<Route path="/BusinessTemplate10" element={<BTemplate10 />} />

				<Route path="/Tasting" element={<Tasting />} />

				<Route element={<RouteWrapper />}>
					{/* Personal */}
					<Route path="/64e369b0c5b49890c7da330d" element={<Template1 />} />
					<Route path="/64e8f2c3b9b30c1ed4b28bb6" element={<Template2 />} />
					<Route path="/64ec9d9bb9b30c1ed4b48bcc" element={<Template3 />} />
					<Route path="/64ec9dafb9b30c1ed4b48bd9" element={<Template4 />} />
					<Route path="/64ec9dbcb9b30c1ed4b48be6" element={<Template5 />} />
					<Route path="/64ec9dc9b9b30c1ed4b48bf3" element={<Template6 />} />
					<Route path="/64ec9f75b9b30c1ed4b48c04" element={<Template7 />} />
					<Route path="/64eca015b9b30c1ed4b48c13" element={<Template8 />} />
					<Route path="/64eca333b9b30c1ed4b48fd7" element={<Template9 />} />
					<Route path="/64eca4efb9b30c1ed4b48fe4" element={<Template10 />} />
					<Route path="/6515ba64f8dd97fbff6724b8" element={<Template11 />} />
					<Route path="/6515bbf5f8dd97fbff672532" element={<Template12 />} />
					<Route path="/6532ada423d177ec023fe738" element={<Template13 />} />
					<Route path="/6532afa623d177ec023fe747" element={<Template14 />} />
					<Route path="/6532b24523d177ec023fe75c" element={<Template15 />} />
					{/* Business */}
					<Route path="/6571b18bd22c6985f652f2c2" element={<BTemplate1 />} />
					<Route path="/6571b4d7d22c6985f652f30f" element={<BTemplate2 />} />
					<Route path="/6571ba44d22c6985f652f352" element={<BTemplate4 />} />
					<Route path="/65956282af838590e8618e4e" element={<BTemplate5 />} />
				</Route>
			</Routes>
		</>
	);
};

export default App;
