import React, { useEffect, useState } from "react";
import comment from "../../assets/business/image/Comment.png";
import heart from "../../assets/business/image/Hearts.png";
import share from "../../assets/business/image/Share.png";
import { defaultProductData } from "../../redux/store";
import styles from "./Template4.module.scss";

function Container4() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		function handelResize() {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [width, height]);

	return (
		<div className={styles.Container4}>
			<div className={styles.top}>
				<div className={styles.ourProducts}>Our Products</div>
				<div className={styles.viewAll}>View All</div>
			</div>
			<div className={styles.products}>
				{width < 950 && height > 600
					? defaultProductData?.slice(0, 4).map((item,i) => (
							<div className={styles.productCardMob} key={i}>
								<img src={item.mediaUrl} alt="" />
								<h3>{item.productName}</h3>
								<div className={styles.actions}>
									<div className={styles.like}>
										<img src={heart} alt="" />
									</div>
									<div className={styles.comment}>
										<img src={comment} alt="" />
									</div>
									<div className={styles.share}>
										<img src={share} alt="" />
									</div>
								</div>
							</div>
					  ))
					: defaultProductData?.slice(0, 3).map((item,i) => (
							<div className={styles.productCard} key={i}>
								<img src={item.mediaUrl} alt="" />
								<h3>{item.productName}</h3>
								<div className={styles.actions}>
									<div className={styles.like}>
										<img src={heart} alt="" />
									</div>
									<div className={styles.comment}>
										<img src={comment} alt="" />
									</div>
									<div className={styles.share}>
										<img src={share} alt="" />
									</div>
								</div>
							</div>
					  ))}
			</div>
		</div>
	);
}

export default Container4;
