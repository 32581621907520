import React from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ratingIcon from "../../assets/images/rating.png";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template12.module.scss";

function Container1({
	editable,
	name,
	email,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	coverText,
	setCoverText,
	followers,
	followings,
	ratingData,
	setShowRating,
	coverImgPosition,
	setCoverImgPosition,
}) {
	const onContentBlur = CreateSanitizeCallback(setCoverText);

	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={style.Container1}>
			<div className={style.leftSection}>
				<div className={style.hero}>
					{!editable && (
						<ImageUpload
							className={style.coverChangeIcon}
							setImage={setCoverImage}
							setImageMobile={setCoverImageMobile}
							image={coverImage}
							imageMobile={coverImageMobile}
							activeId={"coverImg"}
							initialPosition={coverImgPosition}
							setInitialPosition={setCoverImgPosition}
						/>
					)}
					<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImg"}>
						{width < 931 && height > 600 ? (
							<img src={coverImageMobile} alt="coverImageMobile" />
						) : (
							<img src={coverImage} alt="coverImage" />
						)}
					</ImageRender>

					<div className={style.overlay1}></div>
					<div className={style.overlay2}></div>

					<div className={style.followers}>
						{userId === currentUserId ? (
							<div
								style={{ cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}
								onClick={() => {
									window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
								}}>
								<div className={style.followerCount}>{followers}</div>
								<div className={style.textFollower}>Followers</div>
							</div>
						) : (
							<>
								<div className={style.followerCount}>{followers}</div>
								<div className={style.textFollower}>Followers</div>
							</>
						)}

						<div className={style.rating} onClick={handleRating}>
							<img src={ratingIcon} alt="" />
							<span>{ratingData}</span>
						</div>
					</div>
				</div>
			</div>
			<div className={style.rightSection}>
				<div className={style.iam}>I'm</div>
				<div className={style.name}>{name}</div>
				<div className={style.email}>{email}</div>
				<div className={style.TextWrapper}>
					<ContentEditableDiv className={style.coverText} text={coverText} onChange={onContentBlur} contentEditable={!editable} />
					{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
				</div>

				<div className={style.buttons}>
					{userId === currentUserId ? (
						<button
							className={style.follow}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<p style={{ marginBottom: "0.2rem" }}>{followings}</p>
							Following
						</button>
					) : (
						<FollowButton className={style.follow} />
					)}
					<MessageButton className={style.message} />
				</div>
			</div>
		</div>
	);
}

export default Container1;
