import React, { useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../components/Hooks/axios";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import LikeBtn from "../../components/LikeButton/LikeBtn";
import TextArea from "../../components/TextArea";
import { defaultPostData } from "../../redux/store";
import CommentIcon from "../Template1/images/CommentIcon.svg";
import ShareIcon from "../Template1/images/ShareIcon.svg";
import styles from "./Template3.module.scss";

const Container4 = ({ editable, setEditable, shortMessage, setShortMessage }) => {
	const navigate = useNavigate();
	const { userId } = useSelector((state) => state.auth);
	const [postData, setPostData] = useState([]);

	const [index, setIndex] = useState(0);
	const [mouseOver, setMouseOver] = useState(false);
	const post1Ref = useRef();
	const post2Ref = useRef();
	const post3Ref = useRef();
	const post4Ref = useRef();
	const post5Ref = useRef();

	useEffect(() => {
		if (userId === "") return setPostData(defaultPostData);
		axios
			.get(`/post/${userId}`)
			.then(({ data }) => {
				if (data.length === 0) setPostData(defaultPostData);
				else setPostData(data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId]);

	useEffect(() => {
		if (index === 0 && postData[0]) post1Ref?.current?.click();
		if (index === 1 && postData[1]) post2Ref?.current?.click();
		if (index === 2 && postData[2]) post3Ref?.current?.click();
		if (index === 3 && postData[3]) post4Ref?.current?.click();
		if (index === 4 && postData[4]) post5Ref?.current?.click();

		const currentIndex = setInterval(() => {
			if (index < 5) if (!mouseOver) if (postData.length > 1) setIndex(index + 1);
		}, 5000);

		setTimeout(() => {
			if (index === postData?.length - 1 || index === 4) setIndex(0);
		}, 5000);

		return () => clearInterval(currentIndex);
	}, [index, mouseOver, postData]);

	const handleGallery = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		navigate("/Gallery");
	};

	return (
		<div className={styles.container4}>
			<h1>
				My Gallery <p onClick={handleGallery}>View All</p>
			</h1>

			<button
				onClick={() => {
					if (index > 0) setIndex(index - 1);
				}}
				className={styles.LeftArrow}>
				<FiArrowLeft />
			</button>
			<button
				onClick={() => {
					if (index < 4) setIndex(index + 1);
				}}
				className={styles.RightArrow}>
				<FiArrowRight />
			</button>

			<div className={styles.sliderContainer}>
				<div className={styles.CarouselWrapper}>
					<div className={styles.CarouselContainer}>
						{postData[0] && <input type="radio" name="btn" id="temp3post1" onClick={() => setIndex(0)} ref={post1Ref} />}
						{postData[1] && <input type="radio" name="btn" id="temp3post2" onClick={() => setIndex(1)} ref={post2Ref} />}
						{postData[2] && <input type="radio" name="btn" id="temp3post3" onClick={() => setIndex(2)} ref={post3Ref} />}
						{postData[3] && <input type="radio" name="btn" id="temp3post4" onClick={() => setIndex(3)} ref={post4Ref} />}
						{postData[4] && <input type="radio" name="btn" id="temp3post5" onClick={() => setIndex(4)} ref={post5Ref} />}

						{postData?.slice(0, 5).map((data, i) => {
							return (
								<div
									key={i}
									className={index === i ? styles.ActiveImageContainer : styles.ImageContainer}
									id={i === 0 ? "ActiveImageContainer" : ""}
									onMouseEnter={() => {
										if (index === i) setMouseOver(true);
									}}
									onMouseLeave={() => setMouseOver(false)}>
									{data.mediaType === "video" ? (
										<video src={data.mediaUrl[0]} controls></video>
									) : data.mediaType === "image" ? (
										<img src={data.mediaUrl[0]} alt="" />
									) : (
										""
									)}

									<div className={index === i ? styles.ActiveFloatingContainer : styles.DisableFloatingContainer}>
										<div className={styles.Left}>{data?.caption}</div>
										<div className={styles.Right}>
											<LikeBtn data={data} Icon={styles.Icon} LikeIcon={styles.LikeIcon} />
											<div className={styles.Icon}>
												<img src={CommentIcon} alt="Comment" />
												<p>{data?.Comments?.length}</p>
											</div>
											<div className={styles.Icon}>
												<img src={ShareIcon} alt="Share" />
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			<TextArea
				className={styles.shortMessage}
				onClick={() => setEditable(false)}
				readOnly={editable}
				value={shortMessage}
				setValue={setShortMessage}
			/>
			<h4>Thank you ! Visit Again !</h4>
		</div>
	);
};

export default Container4;
