import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Btemplate7.module.scss";
import chooseusframe from "./assets/chooseusFrame.png";

function Container3({ editable, chooseusImage, setChooseusImage, chooseusText, setChooseusText }) {
	const onContentBlur = CreateSanitizeCallback(setChooseusText);
	return (
		<div className={styles.Container3}>
			<div className={styles.leftSection}>
				<div className={styles.content}>
					{!editable && <ImageUpload className={styles.chooseusChangeIcon} setImage={setChooseusImage} image={chooseusImage} />}
					<div className={styles.backImg}>
						<img src={chooseusImage} alt="" />
					</div>
					<div className={styles.frame}>
						<img src={chooseusframe} alt="" />
					</div>
				</div>
			</div>
			<div className={styles.rightSection}>
				<h2>Why Choose Us</h2>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<ContentEditableDiv
					className={styles.chooseusText}
					text={chooseusText}
					onChange={onContentBlur}
					contentEditable={!editable}
				/>
			</div>
		</div>
	);
}

export default Container3;
