import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import style from "./Template4.module.scss";
import deskFrame1 from "./images/IntroAsset.png";
import mobFrame1 from "./images/IntroAssetMob.png";

function Container2({
	editable,
	introImage,
	introImageMobile,
	setIntroImage,
	setIntroImageMobile,
	introText,
	setIntroText,
	setShowAboutMe,
	introImgPosition,
	setIntroImgPosition,
}) {
	const onContentBlur = CreateSanitizeCallback(setIntroText);
	const [widtha, setWidtha] = useState(window.innerWidth);
	const [heighta, setHeighta] = useState(window.innerHeight);
	useEffect(() => {
		function handelResize() {
			setWidtha(window.innerWidth);
			setHeighta(window.innerHeight);
		}
		window.addEventListener("resize", handelResize);
		return () => window.removeEventListener("resize", handelResize);
	}, [widtha, heighta]);

	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={style.container2}>
			<div className={style.introImage}>
				<ImageRender initialPosition={introImgPosition} editable={editable} currentId={"IntroImg"}>
					{width < 950 && height > 600 ? (
						<img src={introImageMobile} alt="introImageMobile" />
					) : (
						<img src={introImage} alt="introImage" />
					)}
				</ImageRender>
				{!editable && (
					<ImageUpload
						className={style.introChangeIcon}
						setImage={setIntroImage}
						setImageMobile={setIntroImageMobile}
						image={introImage}
						imageMobile={introImageMobile}
						activeId={"IntroImg"}
						initialPosition={introImgPosition}
						setInitialPosition={setIntroImgPosition}
					/>
				)}

				<div className={style.introFrame}>
					{widtha < 950 && heighta > 600 ? (
						<img src={mobFrame1} loading="lazy" alt="" />
					) : (
						<img src={deskFrame1} loading="lazy" alt="" />
					)}
				</div>
			</div>
			<div className={style.myIntro}>My Intro</div>
			<div className={style.descWrapper}>
				{!editable && <img src={EditTextIcon} alt="" className={style.EditTextIcon} />}
				<ContentEditableDiv className={style.desc} text={introText} onChange={onContentBlur} contentEditable={!editable} />
			</div>
			<div className={style.buttonWrapper}>
				<button onClick={() => setShowAboutMe(true)}>About Me</button>
				{/* <button>Hire Me</button> */}
			</div>
		</div>
	);
}

export default Container2;
