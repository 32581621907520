import React from "react";
import bbc from "../../assets/business/image/bbc.png";
import cnbc from "../../assets/business/image/cnbc.png";
import newyorkTimes from "../../assets/business/image/newyorkTimes.png";
import time from "../../assets/business/image/time.png";
import dialer from "../../assets/business/svg/dialer.svg";
import location from "../../assets/business/svg/location.svg";
import message from "../../assets/business/svg/message.svg";
import store from "../../assets/business/svg/store.svg";
import whatsapp from "../../assets/business/svg/whatsapp.svg";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import rating from "../../assets/images/rating.png";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Btemplate7.module.scss";
import addPerson from "./assets/addPerson.svg";
import postDummy from "./assets/postDummy.svg";

function Container1({
	editable,
	landingImage,
	setLandingImage,
	businessLogo,
	setBusinessLogo,
	businessName,
	setBusinessName,
	ratingData,
	setShowRating,
}) {
	const postCount = 380;
	const subscriberCount = 480;
	const onContentBlur = CreateSanitizeCallback(setBusinessName);
	return (
		<div className={styles.Container1}>
			<div className={styles.background}>
				<img src={landingImage} alt="" />
			</div>
			<div className={styles.mainSection}>
				<div className={styles.section1}>
					<div className={styles.logo}>
						<img src={businessLogo} alt="LOGO.png" />
					</div>
					<div className={styles.socials}>
						<div className={styles.whatsapp}>
							<img src={whatsapp} alt="" />
						</div>
						<div className={styles.phone}>
							<img src={dialer} alt="" />
						</div>
						<div className={styles.mail}>
							<img src={message} alt="" />
						</div>
						<div className={styles.location}>
							<img src={location} alt="" />
						</div>
						<div className={styles.store}>
							<img src={store} alt="" />
						</div>
					</div>
				</div>
				<div className={styles.section2}>
					<div className={styles.heroSection}>
						{!editable && <ImageUpload className={styles.landingChangeIcon} setImage={setLandingImage} image={landingImage} />}
						<div className={styles.backImage}>
							<img src={landingImage} alt="" />
						</div>
						<div className={styles.details}>
							{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
							<ContentEditableDiv
								className={styles.businessName}
								text={businessName}
								onChange={onContentBlur}
								contentEditable={!editable}
							/>
							<div className={styles.buttons}>
								<button>Message</button>
								<button>Subscribe</button>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.section3}>
					<div className={styles.subscribers}>
						<div className={styles.avatar}>
							<img src={addPerson} alt="" />
						</div>
						<p>{"+" + postCount}</p>
						<p>subscribers</p>
					</div>
					<div className={styles.posts}>
						<div className={styles.avatar}>
							<img src={postDummy} alt="" />
						</div>
						<p>{"+" + subscriberCount}</p>
						<p>post</p>
					</div>
					<div className={styles.RatingContainer} onClick={() => setShowRating(true)}>
						<img src={rating} alt="" />
						<p>{ratingData}</p>
					</div>
				</div>
			</div>
			<div className={styles.adds}>
				<marquee direction="left" behavior="scroll" scrollamount="10" className={styles.logos}>
					<img src={cnbc} alt="logo1" className={styles.cnbc} />
					<img src={bbc} alt="logo2" className={styles.bbc} />
					<img src={newyorkTimes} alt="logo3" className={styles.newyorkTimes} />
					<img src={time} alt="logo4" className={styles.time} />
				</marquee>
			</div>
		</div>
	);
}

export default Container1;
