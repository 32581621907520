import React from "react";
import { useSelector } from "react-redux";
import ratingIcon from "../../assets/images/rating.png";
import ImageRender from "../../components/EditingTool/ImageRender";
import FollowButton from "../../components/GlobalButtons/FollowButton";
import MessageButton from "../../components/GlobalButtons/MessageButton";
import { redirectToSocialMedia } from "../../components/Hooks/RedirectToSocialMedia";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template1.module.scss";

const Container1 = ({
	editable,
	name,
	reel1,
	reel1Mobile,
	setReel1,
	setReel1Mobile,
	reel2,
	reel2Mobile,
	setReel2,
	setReel2Mobile,
	reel3,
	reel3Mobile,
	setReel3,
	setReel3Mobile,
	coverImage,
	coverImageMobile,
	setCoverImage,
	setCoverImageMobile,
	postCount,
	followers,
	followings,
	setShowRating,
	ratingData,
	coverImgPosition,
	setCoverImgPosition,
	reelImg1Position,
	reelImg2Position,
	reelImg3Position,
	setReelImg1Position,
	setReelImg2Position,
	setReelImg3Position,
}) => {
	const { userId, currentUserId, width, height } = useSelector((state) => state.auth);

	const handleRating = () => {
		if (redirectToSocialMedia()) return window.open(process.env.REACT_APP_SOCIAL_MEDIA_URL, "_self");
		setShowRating(true);
	};

	return (
		<div className={styles.Container1}>
			{!editable && (
				<ImageUpload
					className={styles.Reel1UploadBtn}
					setImage={setReel1}
					setImageMobile={setReel1Mobile}
					image={reel1}
					imageMobile={reel1Mobile}
					activeId={"reelImg1Position"}
					initialPosition={reelImg1Position}
					setInitialPosition={setReelImg1Position}
				/>
			)}
			{!editable && (
				<ImageUpload
					className={styles.Reel2UploadBtn}
					setImage={setReel2}
					setImageMobile={setReel2Mobile}
					image={reel2}
					imageMobile={reel2Mobile}
					activeId={"reelImg2Position"}
					initialPosition={reelImg2Position}
					setInitialPosition={setReelImg2Position}
				/>
			)}
			{!editable && (
				<ImageUpload
					className={styles.Reel3UploadBtn}
					setImage={setReel3}
					setImageMobile={setReel3Mobile}
					image={reel3}
					imageMobile={reel3Mobile}
					activeId={"reelImg3Position"}
					initialPosition={reelImg3Position}
					setInitialPosition={setReelImg3Position}
				/>
			)}
			{!editable && (
				<ImageUpload
					className={styles.coverImage}
					setImage={setCoverImage}
					setImageMobile={setCoverImageMobile}
					condition={"png"}
					image={coverImage}
					imageMobile={coverImageMobile}
					activeId={"coverImgPosition"}
					initialPosition={coverImgPosition}
					setInitialPosition={setCoverImgPosition}
				/>
			)}

			<div className={styles.Left}>
				<h4>I’m</h4>
				<div
					className={styles.name}
					style={{
						fontSize: name.length > 12 ? (name.length >= 22 ? (name.length >= 40 ? "4rem" : "5rem") : "7rem") : "9rem",
					}}>
					{name}
				</div>
			</div>

			<div className={styles.Right}>
				<ImageRender initialPosition={coverImgPosition} editable={editable} currentId={"coverImgPosition"}>
					{width < 900 && height > 400 ? (
						<img src={coverImageMobile} alt="coverImageMobile" />
					) : (
						<img src={coverImage} alt="coverImage" />
					)}
				</ImageRender>
			</div>

			<div className={styles.BottomPart}>
				<div className={styles.LeftInner}>
					<div className={styles.RatingContainer} onClick={handleRating}>
						<img src={ratingIcon} alt="" />
						<span>{ratingData}</span>
					</div>

					{userId === currentUserId ? (
						<div
							className={styles.TextContainer}
							style={{ cursor: "pointer" }}
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<h3>{followers}</h3>
							<p>Followers</p>
						</div>
					) : (
						<div className={styles.TextContainer}>
							<h3>{followers}</h3>
							<p>Followers</p>
						</div>
					)}

					<div className={styles.TextContainer}>
						<h2>{postCount}</h2>
						<p>Posts</p>
					</div>
				</div>

				<div className={styles.RightInner}>
					<MessageButton />
					{userId === currentUserId ? (
						<button
							onClick={() => {
								window.open(`${process.env.REACT_APP_SOCIAL_MEDIA_URL}/FollowList`, "_self");
							}}>
							<p>{followings}</p>
							Following
						</button>
					) : (
						<FollowButton />
					)}
				</div>
			</div>

			<div className={styles.RealContainer}>
				<div className={styles.Dots}>
					{Array(100)
						.fill("")
						.map((data, index) => (
							<div key={index} className={styles.Dot}></div>
						))}
				</div>

				<div className={styles.ImageContainer}>
					<div className={styles.InnerImageContainer}>
						<ImageRender initialPosition={reelImg1Position} editable={editable} currentId={"reelImg1Position"}>
							{width < 900 && height > 400 ? <img src={reel1Mobile} alt="reel1Mobile" /> : <img src={reel1} alt="reel1" />}
						</ImageRender>
					</div>

					<div className={styles.InnerImageContainer}>
						<ImageRender initialPosition={reelImg2Position} editable={editable} currentId={"reelImg2Position"}>
							{width < 900 && height > 400 ? <img src={reel2Mobile} alt="reel2mobile" /> : <img src={reel2} alt="reel2" />}
						</ImageRender>
					</div>

					<div className={styles.InnerImageContainer}>
						<ImageRender initialPosition={reelImg3Position} editable={editable} currentId={"reelImg3Position"}>
							{width < 900 && height > 400 ? <img src={reel3Mobile} alt="reel3Mobile" /> : <img src={reel3} alt="reel3" />}
						</ImageRender>
					</div>
				</div>

				<div className={styles.Dots}>
					{Array(100)
						.fill("")
						.map((data, index) => +(<div key={index} className={styles.Dot}></div>))}
				</div>
			</div>
		</div>
	);
};

export default Container1;
