import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import { ContentEditableDiv, CreateSanitizeCallback } from "../../components/ContentEditable/ContentEditable";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import styles from "./Template4.module.scss";
import { useSelector } from "react-redux";

function Container2({
	editable,
	aboutCompanyText,
	aboutCompanyImg,
	aboutCompanyImgMobile,
	setAboutCompanyText,
	setAboutCompanyImg,
	setAboutCompanyImgMobile,
	aboutCompanyImgPosition,
	setAboutCompanyImgPosition,
}) {
	const onContentBlur = CreateSanitizeCallback(setAboutCompanyText);
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.Container2}>
			<div className={styles.leftSection}>
				<div className={styles.frame}></div>
				<div className={styles.heroImage}>
					{!editable && (
						<ImageUpload
							className={styles.aboutusChangeIcon}
							setImage={setAboutCompanyImg}
							setImageMobile={setAboutCompanyImgMobile}
							image={aboutCompanyImg}
							imageMobile={aboutCompanyImgMobile}
							activeId={"aboutCompanyImgPosition"}
							initialPosition={aboutCompanyImgPosition}
							setInitialPosition={setAboutCompanyImgPosition}
						/>
					)}
					<ImageRender initialPosition={aboutCompanyImgPosition} editable={editable} currentId={"aboutCompanyImgPosition"}>
						{width < 931 && height > 600 ? (
							<img src={aboutCompanyImgMobile} alt="aboutCompanyImgMobile" />
						) : (
							<img src={aboutCompanyImg} alt="aboutCompanyImg" />
						)}
					</ImageRender>
				</div>
			</div>
			<div className={styles.rightSection}>
				<h2>About Company</h2>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<ContentEditableDiv
					className={styles.aboutusText}
					text={aboutCompanyText}
					onChange={onContentBlur}
					contentEditable={!editable}
				/>
				<button>We're Hiring</button>
			</div>
		</div>
	);
}

export default Container2;
