import React from "react";
import EditTextIcon from "../../assets/images/EditTextIcon.svg";
import ImageRender from "../../components/EditingTool/ImageRender";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import TextArea from "../../components/TextArea";
import styles from "./Template5.module.scss";
import { useSelector } from "react-redux";

function Container2({
	editable,
	introText,
	setIntroText,
	introImage1,
	introImage1Mobile,
	setIntroImage1,
	setIntroImage1Mobile,
	introImg1Position,
	setIntroImg1Position,
	setShowAboutMe,
}) {
	const { width, height } = useSelector((state) => state.auth);
	return (
		<div className={styles.container2}>
			<div className={styles.hero}>
				<div className={styles.leftImage}>
					{/* <div className={styles.introFrame}></div> */}
					<ImageRender initialPosition={introImg1Position} editable={editable} currentId={"IntroImg"}>
						{width < 950 && height > 600 ? (
							<img src={introImage1Mobile} alt="introImage1Mobile" />
						) : (
							<img src={introImage1} alt="introImage1" />
						)}
					</ImageRender>
					{!editable && (
						<ImageUpload
							className={styles.introChangeIcon}
							setImage={setIntroImage1}
							setImageMobile={setIntroImage1Mobile}
							image={introImage1}
							imageMobile={introImage1Mobile}
							activeId={"IntroImg"}
							initialPosition={introImg1Position}
							setInitialPosition={setIntroImg1Position}
						/>
					)}
				</div>
			</div>
			<div className={styles.content}>
				{!editable && <img src={EditTextIcon} alt="" className={styles.EditTextIcon} />}
				<div className={styles.myIntro}>my intro</div>
				<TextArea className={styles.introDesc} readOnly={editable} value={introText} setValue={setIntroText} />
				<div className={styles.buttons}>
					<button onClick={() => setShowAboutMe(true)}>About Me</button>
					{/* <button>Hire Me</button> */}
				</div>
			</div>
		</div>
	);
}

export default Container2;
